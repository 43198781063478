import React, { useContext, useCallback, useState, useEffect, useRef } from 'react';
import * as tf from '@tensorflow/tfjs'; // Import TensorFlow.js
import { FrailtyModelContext } from './FrailtyModelContext';
import Quarterbacks from './Quarterbacks';
import UserHeader from './UserHeader';
import { calculateInjuryScore } from './injuryScoreCalculator'; // Import the utility function

const FrailtyQB = () => {
    const { model, loading, error } = useContext(FrailtyModelContext);  // Access the model from context
    const [searchTerm, setSearchTerm] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false); // State to manage modal open/close
    const [adjustedFrailtyScore, setAdjustedFrailtyScore] = useState(null); // State for the adjusted frailty score
    const modalRef = useRef(null);

    // Function to handle the prediction of frailty score
    const predictFrailty = useCallback(async (playerDetails, playerStats) => {
        if (!model) {
            console.error("Model is not loaded yet. Cannot predict frailty.");
            return null;
        }

        try {
            const data = tf.tensor2d([Object.values(playerStats)], [1, 12]);
            const predictions = model.predict(data);
            const frailtyScoreTensor = await predictions.data();
            const frailtyScore = frailtyScoreTensor[0] * 10;
            return frailtyScore.toFixed(1);
        } catch (error) {
            console.error("Error during frailty prediction:", error);
            return null;
        }
    }, [model]);

    // Function to calculate the initial adjusted frailty score based on the position and injuries
    const calculateAdjustedFrailtyScore = useCallback((frailtyScore, playerDetails) => {
        let adjustedFrailtyScore = parseFloat(frailtyScore);
        const minOriginal = 0.0;
        const maxOriginal = 6.0;
        const minTarget = 1.0;
        const maxTarget = 10.0;
    
        // Ensure the score stays between min and max ranges
        adjustedFrailtyScore = Math.min(Math.max(adjustedFrailtyScore, minOriginal), maxOriginal);
        let mappedFrailtyScore = minTarget + ((adjustedFrailtyScore - minOriginal) * (maxTarget - minTarget) / (maxOriginal - minOriginal));
    
        // Call the InjuryScoreCalculator function directly (since it's not a class)
        const injuryAdjustedFrailtyScore = calculateInjuryScore(mappedFrailtyScore, playerDetails);
    
        // Return the injury-adjusted frailty score
        return injuryAdjustedFrailtyScore;
    }, []);
    // Handle click outside the modal to close it
    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (isModalOpen && modalRef.current && !modalRef.current.contains(event.target)) {
                setIsModalOpen(false);
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [isModalOpen]);

    if (loading) {
        return <div>Loading model...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <div>
            <UserHeader />
            <div className="flex flex-col md:flex-row items-start md:items-center space-y-4 md:space-y-0 md:space-x-4 mb-4 p-10">
                <h2 className="text-xl font-bold">Quarterback Frailty Cards</h2>
                <input
                    type="text"
                    placeholder="Search Quarterbacks..."
                    className="w-full md:w-1/3 p-2 border rounded-md"
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
                <button
                    onClick={() => setIsModalOpen(true)} // Open the modal on click
                    className="bg-zinc-900 hover:bg-zinc-950 font-bold hover:text-[#d8bf82] text-white p-2 rounded-md"
                >
                    How Frailty Scores Work
                </button>
            </div>

            {/* Quarterbacks component */}
            <Quarterbacks
                predictFrailty={predictFrailty}
                calculateAdjustedFrailtyScore={calculateAdjustedFrailtyScore}
                searchTerm={searchTerm}
            />

            {/* Modal */}
            {isModalOpen && (
                <div
                    className="fixed inset-0 flex items-center justify-center z-50"
                    style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
                >
                    <div ref={modalRef} className="relative bg-white rounded-lg p-8 max-w-lg w-full mx-4 h-4/5 overflow-y-auto">
                        <h2 className="text-2xl font-bold mb-4 ">How Frailty Scores Work</h2>
                        <p className="mb-4">
                            Injsur.ai is a cutting-edge application that uses advanced machine learning to assess the frailty of NFL players. Our proprietary model, trained on extensive player data dating back to 2005, provides our users with crucial insights into the statistical probability of player health and performance.
                        </p>
                        <p className="mb-4">
                            The Injsur model is built on a comprehensive dataset including player demographics (age, height, weight, position), performance statistics (yards, touchdowns, tackles, etc.), current physical performance metrics, detailed injury history, workload data (snap counts, practice participation) and more...
                        </p>
                        <p className="mb-4">
                            Our proprietary machine learning model is developed using state-of-the-art techniques to produce an accurate Frailty Score showing the level of risk of injury for each player.
                        </p>
                        <p className="mb-4">
                            The Injsur algorithm processes player data and outputs a Frailty Score on a scale of 0-10.
                        </p>
                        <ul className="mb-4 list-disc list-inside">
                            <li className='font-bold'>0-3:  <span className='ml-1 font-bold text-green-500'>Low risk</span></li>
                            <li className='font-bold'>4-7:  <span className='ml-1 font-bold text-yellow-600'>Moderate risk</span></li>
                            <li className='font-bold'>8-10: <span className='ml-1 font-bold text-red-500'>High risk</span></li>
                        </ul>
                        <button
                            onClick={() => setIsModalOpen(false)} // Close the modal on click
                            className="bg-red-500 hover:bg-red-600 text-white p-2 rounded-md mt-4"
                        >
                            Close
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default FrailtyQB;
