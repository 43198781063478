// UserContext.js

import { createContext, useContext, useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';

const UserContext = createContext();

export function UserProvider({ children }) {
    const [user, setUser] = useState(null);

    return <UserContext.Provider value={{ user, setUser }}>{children}</UserContext.Provider>;
}

export function useUser() {
    const { user, setUser } = useContext(UserContext);

    useEffect(() => {
        const checkUser = async () => {
            try {
                const cognitoUser = await Auth.currentAuthenticatedUser();
                setUser({
                    username: cognitoUser.username,
                    firstName: cognitoUser.attributes['custom:first_name'],
                    lastName: cognitoUser.attributes['custom:last_name'],
                    phoneNumber: cognitoUser.attributes.phone_number,
                    email: cognitoUser.attributes.email,
                    city: cognitoUser.attributes['custom:city'],
                    state: cognitoUser.attributes['custom:state'],
                    zip: cognitoUser.attributes['custom:zip'],
                    address: cognitoUser.attributes['custom:address'],
                    subscription_id: cognitoUser.attributes['custom:subscription_id'],
                    customer_id: cognitoUser.attributes['custom:customer_id'],
                });
            } catch (error) {
                console.error('Error checking user authentication:', error);
                setUser(null);
            }
        };

        checkUser();
    }, [setUser]);

    return user;
}

export function useSetUser() {
    const { setUser } = useContext(UserContext);
    return setUser;
}
