import React from 'react';
import Header2 from './Header2';
import Footer from './Footer';

const TermsOfUse = () => {
    return (
        <div>
            <Header2 />
            <div className="terms-and-conditions-container px-4 sm:px-10 md:px-20 lg:px-40 xl:px-60 py-16 text-green-950">
                <h1 className="text-4xl font-bold mb-8">Injsur Terms and Conditions</h1>
                <p className="mb-8 font-bold">Effective: August 16th, 2024</p>
                <p className="mb-8">
                    PLEASE READ THE TERMS AND CONDITIONS CAREFULLY. THE TERMS AND CONDITIONS (“AGREEMENT”) CONSTITUTE A LEGAL AGREEMENT BETWEEN YOU AND INJSUR.
                </p>
                <p className="mb-8">
                    SECTION 15 OF THIS AGREEMENT CONTAINS PROVISIONS THAT GOVERN HOW CLAIMS THAT YOU AND WE HAVE AGAINST EACH OTHER ARE RESOLVED, INCLUDING, WITHOUT LIMITATION, ANY CLAIMS THAT AROSE OR WERE ASSERTED BEFORE THE EFFECTIVE DATE OF THIS AGREEMENT. IN PARTICULAR, SECTION 15 SETS FORTH OUR ARBITRATION AGREEMENT WHICH WILL, WITH LIMITED EXCEPTIONS, REQUIRE DISPUTES BETWEEN US TO BE SUBMITTED TO BINDING AND FINAL ARBITRATION. UNLESS YOU OPT OUT OF THE ARBITRATION AGREEMENT: (1) YOU WILL ONLY BE PERMITTED TO PURSUE CLAIMS AND SEEK RELIEF AGAINST US ON AN INDIVIDUAL BASIS, NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY CLASS OR REPRESENTATIVE ACTION OR PROCEEDING; AND (2) YOU ARE WAIVING YOUR RIGHT TO SEEK RELIEF IN A COURT OF LAW AND TO HAVE A JURY TRIAL ON YOUR CLAIMS. PLEASE SEE SECTION 15 FOR MORE INFORMATION REGARDING THIS ARBITRATION AGREEMENT, THE POSSIBLE EFFECTS OF THIS ARBITRATION AGREEMENT, AND HOW TO OPT OUT OF THE ARBITRATION AGREEMENT.
                </p>

                <p className="mb-8">Please click the following links to learn more about our Terms and Conditions:</p>
                <ul className="list-disc ml-8 mb-8">
                    <li><a href="#purpose" className="text-green-950 underline">Purpose</a></li>
                    <li><a href="#no-professional-advice" className="text-green-950 underline">No Professional or Medical Advice</a></li>
                    <li><a href="#eligibility" className="text-green-950 underline">Eligibility</a></li>
                    <li><a href="#access" className="text-green-950 underline">Access</a></li>
                    <li><a href="#jurisdiction" className="text-green-950 underline">United States Jurisdiction</a></li>
                    <li><a href="#acceptable-use" className="text-green-950 underline">Acceptable Use Policy</a></li>
                    <li><a href="#information-on-service" className="text-green-950 underline">Information on our Service</a></li>
                    <li><a href="#feedback" className="text-green-950 underline">Feedback</a></li>
                    <li><a href="#intellectual-property" className="text-green-950 underline">Intellectual Property Ownership</a></li>
                    <li><a href="#privacy" className="text-green-950 underline">Privacy</a></li>
                    <li><a href="#third-party-interactions" className="text-green-950 underline">Third-Party Interactions</a></li>
                    <li><a href="#indemnification" className="text-green-950 underline">Indemnification</a></li>
                    <li><a href="#disclaimer-of-warranties" className="text-green-950 underline">Disclaimer of Warranties</a></li>
                    <li><a href="#limitation-of-liability" className="text-green-950 underline">Limitation of Liability</a></li>
                    <li><a href="#dispute-resolution" className="text-green-950 underline">Dispute Resolution</a></li>
                    <li><a href="#exclusive-venue" className="text-green-950 underline">Exclusive Venue</a></li>
                    <li><a href="#termination" className="text-green-950 underline">Termination</a></li>
                    <li><a href="#general" className="text-green-950 underline">General</a></li>
                    <li><a href="#contact-information" className="text-green-950 underline">Contact Information</a></li>
                </ul>

                <h2 id="purpose" className="text-3xl font-bold mb-4">1. Purpose</h2>
                <p className="mb-8">
                    Injsur, Inc. (“Injsur,” “we,” “us,” or “our”) provides online services that include, but are not limited to, predictive player insights, sports information, reviews, news, analysis, forums, and other similar content.
                    This Agreement details how subscribers and other users (collectively, “you,” “your,” or “Users”) can and cannot use our products, services, and websites (collectively, the “Service(s)”). While we will do our best to enforce the terms of this Agreement, we cannot warrant or represent that other Users will in fact adhere to this Agreement and cannot act as insurers or accept any liability for their failure to do so.
                </p>

                <h2 id="no-professional-advice" className="text-3xl font-bold mb-4">2. No Professional or Medical Advice</h2>
                <p className="mb-8">
                    Injsur’s Services and staff members do not give professional or medical advice.
                    Any information supplied by the Services, whether online or by e-mail or other form of communication, is intended solely for general information and entertainment purposes, and does not constitute legal, tax, insurance, accounting, medical, or other professional advice. Individual situations and state laws vary, and users are encouraged to obtain appropriate advice from qualified professionals in the applicable jurisdictions. We make no representations or warranties concerning any course of action taken by any person following or otherwise using the information offered or provided within or through the Services, and we will not be liable for any direct, indirect, consequential, special, exemplary, or other damages that may result, including economic loss, injury, illness, or death.
                </p>

                <h2 id="eligibility" className="text-3xl font-bold mb-4">3. Eligibility</h2>
                <p className="mb-8">
                    By accessing or using the Service in any way, clicking on a button or taking similar action to signify your affirmative acceptance of this Agreement, you hereby represent that:
                </p>
                <ul className="list-disc ml-8 mb-8">
                    <li>You have read, understand, and agree to be bound by this Agreement and any future amendments and additions to this Agreement as published from time to time at this link or through the Service;</li>
                    <li>You are 18 years of age or older;</li>
                    <li>You have the authority to enter into the Agreement personally; and,</li>
                    <li>You will comply with all applicable laws, including those of the country, state and city in which you are present while using the Service.</li>
                </ul>

                <h2 id="access" className="text-3xl font-bold mb-4">4. Access</h2>
                <h3 className="text-2xl font-bold mb-4">a. Access</h3>
                <p className="mb-8">
                    By entering into this Agreement, you will be granted a revocable license to access the Service without charge. Your access privileges, however, are conditioned on your adherence to the terms of this Agreement. We reserve the right to temporarily deny you access to the Service or permanently terminate your access privileges at any time. By agreeing to grant you access, we do not obligate ourselves to do so or to maintain the Service, or to maintain it in its present form, and we expressly reserve the right to modify, suspend, or terminate your access privileges.
                </p>
                <h3 className="text-2xl font-bold mb-4">b. Prohibited Uses</h3>
                <p className="mb-8">
                    You understand, acknowledge and agree that any access or use of the Service shall be for your personal, non-commercial use only, and that you will not resell any portion of the Service.
                </p>
                <h3 className="text-2xl font-bold mb-4">c. Privileges Nontransferable</h3>
                <p className="mb-8">
                    Your access privileges may not be transferred by you to any third parties.
                </p>
                <h3 className="text-2xl font-bold mb-4">d. Passwords and Security</h3>
                <p className="mb-8">
                    You agree not to disclose to anyone your confidential password and to notify us immediately if there has been a breach of your security that affects our Service.
                </p>

                <h2 id="jurisdiction" className="text-3xl font-bold mb-4">5. United States Jurisdiction</h2>
                <p className="mb-8">
                    The Services are operated out of the United States of America. We do not represent that content or materials presented on the Services are appropriate (or, in some cases, will be available) for use in other locations. If you access the Services from a jurisdiction other than the United States, you agree that you do so on your own initiative, and are responsible for compliance with local laws, if and to the extent local laws are applicable to your use of the Services.
                </p>

                <h2 id="acceptable-use" className="text-3xl font-bold mb-4">6. Acceptable Use Policy</h2>
                <p className="mb-8">
                    By using the Service, you agree that:
                </p>
                <ul className="list-disc ml-8 mb-8">
                    <li>You will only use the Service for lawful purposes, and not for deceptive or fraudulent purposes; you will not send or store any unlawful material.</li>
                    <li>You will not use the Service to cause nuisance, annoyance or inconvenience.</li>
                    <li>You will not use the Service, or any content accessible through the Service, for any commercial purpose, including but not limited to contacting, advertising to, soliciting or selling to, any Users.</li>
                    <li>You will not violate the publicity or privacy rights of another individual.</li>
                    <li>You will not copy or distribute any content displayed through the Service.</li>
                    <li>You will not create or compile, directly or indirectly, any collection, compilation, or other directory from any content displayed through the Service except for your personal, noncommercial use.</li>
                    <li>The information you provide to us or otherwise communicate with us is accurate.</li>
                    <li>You will not use the Service in any way that could damage, disable, overburden or impair any of our servers, or the networks connected to any of our servers.</li>
                    <li>You will not attempt to gain unauthorized access to any part of the Service and/or to any service, account, resource, computer system and/or network connected to any of our servers.</li>
                    <li>You will not deep-link to the Service or access the Service manually or with any robot, spider, web crawler, extraction software, automated process and/or device to scrape, copy or monitor any portion of the Service or any content on the Service.</li>
                    <li>You will report any errors, bugs, unauthorized access methodologies or any breach of our intellectual property rights that you uncover in your use of the Service.</li>
                    <li>You will not impersonate another person, act as another entity without authorization, or create multiple accounts.</li>
                    <li>You will not use any part of our Service to develop any artificial intelligence (or similar) models, unless you receive our express, prior written consent pursuant to a separate agreement.</li>
                    <li>You will not automatically or programmatically extract data or outputs from our Services.</li>
                    <li>You will not interfere with or disrupt our Services, including circumventing any rate limits or restrictions, bypassing any protective measures or safety mitigations we put on our Services, or initiating or facilitating Content-based attacks or jailbreaking.</li>
                </ul>

                <h2 id="information-on-service" className="text-3xl font-bold mb-4">7. Information on our Service</h2>
                <p className="mb-8">
                    We are constantly working to improve our AI models to make the product more accurate and reliable. However, given the probabilistic nature of machine learning and artificial intelligence, this may result in output that is inaccurate or incomplete.
                </p>
                <p className="mb-8">
                    While we will always use our best efforts to ensure the accuracy and completeness of information provided on our Service, we cannot guarantee the accuracy, adequacy, quality, or suitability of any data on our Service and expressly disclaim liability for errors and omissions in the contents of our Service. Any use or reliance on any content or materials posted via the Service or obtained by you through the Service is at your own risk. Any link to a website or services owned or managed by a third party does not constitute an endorsement, approval, association, sponsorship, or affiliation with such third party.
                </p>

                <h2 id="feedback" className="text-3xl font-bold mb-4">8. Feedback</h2>
                <p className="mb-8">
                    You agree that any submission of any ideas, suggestions, and/or proposals to us (“Feedback”) is at your own risk and that we have no obligations (including without limitation, obligations of confidentiality) with respect to such Feedback. You represent and warrant that you have all rights necessary to submit the Feedback and you hereby grant to us a perpetual, irrevocable, transferable, fully paid, royalty-free, non-exclusive, worldwide, fully sublicensable right and license to use, copy, display, publish, modify, remove, publicly perform, translate, create derivative works, distribute and/or otherwise use such Feedback, except as otherwise required by law.
                </p>

                <h2 id="intellectual-property" className="text-3xl font-bold mb-4">9. Intellectual Property Ownership</h2>
                <p className="mb-8">
                    We (and our licensors, where applicable) shall own all right, title and interest, including all related intellectual property rights, in and to the Service. This Agreement is not a sale and does not convey to you any rights of ownership in or related to the Service, or any intellectual property rights owned by us. Our name, logo, and the product names associated with the Service are our trademarks or belong to third parties, and no right or license is granted to use them. You agree that you will not remove, alter or obscure any copyright, trademark, service mark or other proprietary rights notices incorporated in or accompanying the Service.
                </p>

                <h2 id="privacy" className="text-3xl font-bold mb-4">10. Privacy</h2>
                <p className="mb-8">
                    We have adopted a Privacy Policy outlining our personal data collection and use practices. Please refer to it for details about how we collect and use your personal information. By agreeing to the terms of this Agreement, you are automatically agreeing to our Privacy Policy, which is incorporated herein by reference.
                </p>

                <h2 id="third-party-interactions" className="text-3xl font-bold mb-4">11. Third-Party Interactions</h2>
                <p className="mb-8">
                    The Service may contain links to or display content originating from third-party websites and advertisements (collectively, “Third-Party Websites & Advertisements”). Such Third-Party Websites & Advertisements are not under our control. We are not responsible for any Third-Party Websites or any Third-Party Advertisements. We do not review, approve, monitor, endorse, warrant, or make any representations with respect to such Third-Party Websites & Advertisements, or their products or services. When you click on a link to a Third-Party Website or Advertisement, we will not warn you that you have left our Service and will not warn you that you are subject to the terms and conditions (including privacy policies) of another website or destination. You use all links in Third-Party Websites & Advertisements at your own risk. You should review applicable terms and policies, including privacy and data gathering practices of any Third-Party Websites, and make whatever investigation you feel necessary or appropriate before proceeding with any transaction with any third party.
                </p>

                <h2 id="indemnification" className="text-3xl font-bold mb-4">12. Indemnification</h2>
                <p className="mb-8">
                    You agree to indemnify and hold harmless Injsur and its officers, directors, employees, agents and affiliates (each, an “Indemnified Party”), from and against any losses, claims, actions, costs, damages, penalties, fines and expenses, including without limitation attorneys’ fees and expenses, that may be incurred by an Indemnified Party arising out of, relating to or resulting from (a) your misuse of the Service; (b) your violation of this Agreement; or (c) your violation of any applicable laws, rules or regulations through or related to the use of the Service. In the event of any claim, allegation, suit or proceeding alleging any matter potentially covered by the agreements in this Section, you agree to pay for the defense of the Indemnified Party, including reasonable costs and attorneys’ fees incurred by the Indemnified Party. We reserve the right, at our own cost, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, in which event you will fully cooperate with us in asserting any available defenses. This provision does not require you to indemnify any Indemnified Party for any unconscionable commercial practice by such party, or for such party’s negligence, fraud, deception, false promise, misrepresentation or concealment, suppression or omission of any material fact in connection with the Service. You agree that the provisions in this Section will survive any termination of your account, this Agreement, or your access to the Service.
                </p>

                <h2 id="disclaimer-of-warranties" className="text-3xl font-bold mb-4">13. Disclaimer of Warranties</h2>
                <p className="mb-8">
                    YOU EXPRESSLY UNDERSTAND AND AGREE THAT TO THE FULLEST EXTENT OF LAW, YOUR USE OF THE SERVICE IS ENTIRELY AT YOUR OWN RISK. CHANGES ARE PERIODICALLY MADE TO THE SERVICE AND MAY BE MADE AT ANY TIME WITHOUT NOTICE TO YOU. THE SERVICE ARE PROVIDED ON AN “AS IS” BASIS WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY, RELIABILITY, COMPLETENESS OR TIMELINESS OF THE CONTENT MADE AVAILABLE THROUGH THE SERVICE, OR THE TEXT, GRAPHICS OR LINKS.
                </p>
                <p className="mb-8">
                    WE DO NOT WARRANT THAT THE SERVICE WILL OPERATE ERROR-FREE OR THAT THE SERVICE ARE FREE OF COMPUTER VIRUSES AND OTHER HARMFUL MALWARE. IF YOUR USE OF THE SERVICE RESULTS IN THE NEED FOR SERVICING OR REPLACING EQUIPMENT OR DATA, WE SHALL NOT BE RESPONSIBLE FOR THOSE ECONOMIC COSTS.
                </p>

                <h2 id="limitation-of-liability" className="text-3xl font-bold mb-4">14. Limitation of Liability</h2>
                <p className="mb-8">
                    UNDER NO CIRCUMSTANCES AND UNDER NO LEGAL THEORY (WHETHER IN CONTRACT, TORT, OR OTHERWISE) SHALL INJSUR BE LIABLE TO YOU OR ANY THIRD PARTY FOR (A) ANY INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, CONSEQUENTIAL OR PUNITIVE DAMAGES, INCLUDING LOST PROFITS, LOST SALES OR BUSINESS, LOST DATA OR DATA BREACH, OR (B) FOR ANY DIRECT DAMAGES, COSTS, LOSSES OR LIABILITIES (INCLUDING ATTORNEYS’ FEES) IN EXCESS OF THE FEES ACTUALLY PAID BY YOU IN THE TWO (2) MONTHS PRECEDING THE EVENT GIVING RISE TO YOUR CLAIM OR, IF NO FEES APPLY, ONE HUNDRED ($100) U.S. DOLLARS.  THE PROVISIONS OF THIS SECTION ALLOCATE THE RISKS UNDER THIS AGREEMENT BETWEEN THE PARTIES, AND THE PARTIES HAVE RELIED ON THESE LIMITATIONS IN DETERMINING WHETHER TO ENTER INTO THIS AGREEMENT. Some states do not allow the exclusion of implied warranties or limitation of liability for incidental or consequential damages, which means that some of the above limitations may not apply to you. IN THESE STATES, OUR LIABILITY WILL BE LIMITED TO THE GREATEST EXTENT PERMITTED BY LAW.
                </p>
                <p className="mb-8">
                    WE MAKE NO REPRESENTATIONS OR WARRANTIES WITH RESPECT TO THE SERVICE, OR ANY OTHER ITEMS OR SERVICE PROVIDED BY US, INCLUDING, WITHOUT LIMITATION, ANY IMPLIED WARRANTY ARISING BY USAGE OF TRADE, COURSE OF DEALING OR COURSE OF PERFORMANCE, ANY IMPLIED WARRANTY OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE AND ANY IMPLIED WARRANTY OF NON-INFRINGEMENT. YOU ACKNOWLEDGE THAT THE SERVICE (INCLUDING ANY SERVERS OR OTHER HARDWARE, SOFTWARE AND ANY OTHER ITEMS USED OR PROVIDED BY US IN CONNECTION WITH THE SERVICE) ARE PROVIDED &quot;AS IS&quot; AND THAT WE MAKE NO WARRANTY THAT THE SERVICE WILL BE FREE FROM BUGS, FAULTS, DEFECTS OR ERRORS OR THAT ACCESS TO THE SERVICE WILL BE UNINTERRUPTED.
                </p>

                <h2 id="dispute-resolution" className="text-3xl font-bold mb-4">15. Dispute Resolution</h2>
                <p className="mb-8">
                    PLEASE READ THE FOLLOWING SECTION CAREFULLY. IT REQUIRES YOU TO ARBITRATE DISPUTES WITH US AND LIMITS THE MANNER IN WHICH YOU CAN SEEK RELIEF. THIS SECTION 15 OF THIS AGREEMENT SHALL BE REFERRED TO AS THE “ARBITRATION AGREEMENT.”
                </p>
                <h3 className="text-2xl font-bold mb-4">a. Scope of Arbitration Agreement</h3>
                <p className="mb-8">
                    You agree that any dispute or claim relating in any way to your access or use of the Service or as a consumer of our services, to any advertising or marketing communications regarding us or our Service, to any products or services sold or distributed through the Service that you received as a consumer, or to any aspect of your relationship or transactions with us as a consumer of our services will be resolved by binding arbitration, rather than in court, except that (1) you may assert claims in small claims court if your claims qualify, so long as the matter remains in such court and advances only on an individual (non-class, non-representative) basis; and (2) you or Injsur may seek equitable relief in court for infringement or other misuse of intellectual property rights (such as trademarks, trade dress, domain names, trade secrets, copyrights, and patents). This Arbitration Agreement shall apply, without limitation, to all claims that arose or were asserted before the Effective Date of this Agreement.
                </p>
                <p className="mb-8">
                    IF YOU AGREE TO ARBITRATION WITH INJSUR, YOU ARE AGREEING IN ADVANCE THAT YOU WILL NOT PARTICIPATE IN OR SEEK TO RECOVER MONETARY OR OTHER RELIEF IN ANY SUCH CLASS, COLLECTIVE, AND/OR REPRESENTATIVE LAWSUIT. INSTEAD, BY AGREEING TO ARBITRATION, YOU MAY BRING YOUR CLAIMS AGAINST US IN AN INDIVIDUAL ARBITRATION PROCEEDING. IF SUCCESSFUL ON SUCH CLAIMS, YOU COULD BE AWARDED MONEY OR OTHER RELIEF BY AN ARBITRATOR.
                </p>
                <h3 className="text-2xl font-bold mb-4">b. Informal Resolution</h3>
                <p className="mb-8">
                    You and Injsur agree that good-faith informal efforts to resolve disputes often can result in a prompt, low-cost and mutually beneficial outcome. You and Injsur therefore agree that, before either you or Injsur demands arbitration against the other, we will personally meet and confer, via telephone or videoconference, in a good-faith effort to resolve informally any claim covered by this mutual Arbitration Agreement. If you are represented by counsel, your counsel may participate in the conference, but you shall also fully participate in the conference. The party initiating the claim must give notice to the other party in writing of its, his, or her intent to initiate an informal dispute resolution conference, which shall occur within 60 days after the other party receives such notice, unless an extension is mutually agreed upon by the parties. To notify Injsur that you intend to initiate an informal dispute resolution conference, email support@injsur.ai, providing your username associated with your Injsur account (if any), the email address associated with your Injsur account (if any), and a description of your claim. In the interval between the party receiving such notice and the informal dispute resolution conference, the parties shall be free to attempt to resolve the initiating party’s claims. Engaging in an informal dispute resolution conference is a requirement that must be fulfilled before commencing arbitration. The statute of limitations and any filing fee deadlines shall be tolled while the parties engage in the informal dispute resolution process required by this paragraph.
                </p>
                <h3 className="text-2xl font-bold mb-4">c. Arbitration Rules and Forum</h3>
                <p className="mb-8">
                    This Arbitration Agreement is governed by the Federal Arbitration Act in all respects. To begin an arbitration proceeding, you must send a letter requesting arbitration and describing your claim to our registered agent, [contact details here]. The arbitration will be conducted by JAMS under its rules and pursuant to the terms of this Agreement. Disputes involving claims and counterclaims under $250,000, not inclusive of attorneys’ fees and interest, shall be subject to JAMS’s most current version of the Streamlined Arbitration Rules and procedures available at http://www.jamsadr.com/rules-streamlined-arbitration/; all other claims shall be subject to JAMS’s most current version of the Comprehensive Arbitration Rules and Procedures, available at http://www.jamsadr.com/rules-comprehensive-arbitration/. JAMS’s rules are also available at www.jamsadr.com (under the Rules/Clauses tab) or by calling JAMS at 800-352-5267. Payment of all filing, administration, and arbitration fees will be governed by JAMS’s rules. If the arbitrator finds that you cannot afford to pay JAMS’s filing, administrative, hearing and/or other fees and cannot obtain a waiver of fees from JAMS, we will pay them for you. In addition, we will reimburse all such JAMS’s filing, administrative, hearing and/or other fees for claims with an amount in controversy totaling less than $10,000. If JAMS is not available to arbitrate, the parties will select an alternative arbitral forum. You may choose to have the arbitration conducted by telephone, video conference, based on written submissions, or in person in the county where you live or at another mutually agreed location.
                </p>
                <h3 className="text-2xl font-bold mb-4">d. Arbitrator Powers</h3>
                <p className="mb-8">
                    The arbitrator, and not any federal, state, or local court or agency, shall have exclusive authority to resolve any dispute relating to the interpretation, applicability, enforceability or formation of this Arbitration Agreement including, but not limited to any claim that all or any part of this Arbitration Agreement is void or voidable. The arbitration will decide the rights and liabilities, if any, of you and Injsur. The arbitration proceeding will not be consolidated with any other matters or joined with any other proceedings or parties. The arbitrator will have the authority to grant motions dispositive of all or part of any claim or dispute. The arbitrator will have the authority to award monetary damages and to grant any non-monetary remedy or relief available to an individual under applicable law, the arbitral forum’s rules, and this Agreement (including this Arbitration Agreement). The arbitrator will issue a written statement of decision describing the essential findings and conclusions on which any award (or decision not to render an award) is based, including the calculation of any damages awarded. The arbitrator shall follow the applicable law. The arbitrator has the same authority to award relief on an individual basis that a judge in a court of law would have. The arbitrator’s decision is final and binding on you and Injsur.
                </p>
                <h3 className="text-2xl font-bold mb-4">e. Waiver of Jury Trial</h3>
                <p className="mb-8">
                    YOU AND INJSUR WAIVE ANY CONSTITUTIONAL AND STATUTORY RIGHTS TO SUE IN COURT AND RECEIVE A JUDGE OR JURY TRIAL. You and Injsur are instead electing to have claims and disputes resolved by arbitration, except as specified in Section 15(a) above. There is no judge or jury in arbitration, and court review of an arbitration award is limited.
                </p>
                <h3 className="text-2xl font-bold mb-4">f. Waiver of Class or Consolidated Actions</h3>
                <p className="mb-8">
                    YOU AND INJSUR AGREE TO WAIVE ANY RIGHT TO RESOLVE CLAIMS WITHIN THE SCOPE OF THIS ARBITRATION AGREEMENT ON A CLASS, COLLECTIVE, OR REPRESENTATIVE BASIS. ALL CLAIMS AND DISPUTES WITHIN THE SCOPE OF THIS ARBITRATION AGREEMENT MUST BE ARBITRATED ON AN INDIVIDUAL BASIS AND NOT ON A CLASS BASIS. CLAIMS OF MORE THAN ONE CUSTOMER OR USER CANNOT BE ARBITRATED OR LITIGATED JOINTLY OR CONSOLIDATED WITH THOSE OF ANY OTHER CUSTOMER OR USER. If, however, this waiver of class or consolidated actions is deemed invalid or unenforceable with respect to a particular claim or dispute, neither you nor Injsur is entitled to arbitration of such claim or dispute. Instead, all such claims and disputes will then be resolved in a court as set forth in Section 16.
                </p>
                <h3 className="text-2xl font-bold mb-4">g. Batch Arbitrations</h3>
                <p className="mb-8">
                    To increase efficiency of resolution, in the event 100 or more similar arbitration demands against Injsur, presented by or with the assistance of the same law firm or organization, are submitted to an arbitration provider selected in accordance with the rules described above within a 30-day period, the arbitration provider shall (i) group the arbitration demands into batches of no more than 100 demands per batch (plus, to the extent there are less than 100 arbitration demands left over after the batching described above, a final batch consisting of the remaining demands); and (ii) provide for resolution of each batch as a single arbitration with one set of filing and administrative fees and one arbitrator assigned per batch. You agree to cooperate in good faith with Injsur and the arbitration provider to implement such a batch approach to resolution and fees.
                </p>
                <h3 className="text-2xl font-bold mb-4">h. Opt Out</h3>
                <p className="mb-8">
                    You may opt out of this Arbitration Agreement. If you do so, neither you nor Injsur can force the other to arbitrate as a result of this Agreement. To opt out, you must notify Injsur in writing no later than 30 days after first becoming subject to this Arbitration Agreement. Your notice must include your name and address, your email address (if you have one), and a CLEAR statement that you want to opt out of this Arbitration Agreement. You must send your opt-out notice to: support@injsur.ai. If you opt out of this Arbitration Agreement, all other parts of this Agreement will continue to apply to you. Opting out of this Arbitration Agreement has no effect on any other arbitration agreements that you may have entered into with us or may enter into in the future with us.
                </p>
                <h3 className="text-2xl font-bold mb-4">i. Survival</h3>
                <p className="mb-8">
                    This Arbitration Agreement will survive any termination of your relationship with us.
                </p>
                <h3 className="text-2xl font-bold mb-4">j. Modification</h3>
                <p className="mb-8">
                    Notwithstanding any provision in the Agreement to the contrary, we agree that if we make any future material change to this Arbitration Agreement, it will not apply to any individual claim(s) that you had already provided notice of to us.
                </p>

                <h2 id="exclusive-venue" className="text-3xl font-bold mb-4">16. Exclusive Venue</h2>
                <p className="mb-8">
                    To the extent the parties are permitted under this Agreement to initiate litigation in a court, both you and Injsur agree that all claims and disputes arising out of or relating to the Agreement will be litigated exclusively within the State of California.
                </p>

                <h2 id="termination" className="text-3xl font-bold mb-4">17. Termination</h2>
                <p className="mb-8">
                    At our sole discretion, we may modify or discontinue the Service, or may modify, suspend or terminate your access to the Service, for any reason, with or without notice to you and without liability to you or any third party. In addition to suspending or terminating your access to the Service, we reserve the right to take appropriate legal action, including without limitation pursuing civil, criminal or injunctive redress. Even after your right to use the Service is terminated, this Agreement will remain enforceable against you. All provisions which by their nature should survive to give effect to those provisions shall survive the termination of this Agreement.
                </p>

                <h2 id="general" className="text-3xl font-bold mb-4">18. General</h2>
                <h3 className="text-2xl font-bold mb-4">a. No Joint Venture or Partnership</h3>
                <p className="mb-8">
                    No joint venture, partnership, employment, or agency relationship exists between you, Injsur or any third-party provider as a result of this Agreement or use of the Service.
                </p>
                <h3 className="text-2xl font-bold mb-4">b. Choice of Law</h3>
                <p className="mb-8">
                    This Agreement is governed by the laws of the State of California consistent with the Federal Arbitration Act, without giving effect to any principles that provide for the application of the law of any other jurisdiction.
                </p>
                <h3 className="text-2xl font-bold mb-4">c. Severability</h3>
                <p className="mb-8">
                    Except as otherwise provided herein, if any provision of this Agreement is found to be invalid, the invalidity of such provision shall not affect the validity of the remaining provisions of this Agreement, which shall remain in full force and effect.
                </p>
                <h3 className="text-2xl font-bold mb-4">d. Electronic Communications</h3>
                <p className="mb-8">
                    For contractual purposes, you (1) consent to receive communications from us in an electronic form; and (2) agree that all terms and conditions, agreements, notices, disclosures, and other communications that we provide to you electronically satisfy any legal requirement that such communications would satisfy if they were in writing. This subparagraph does not affect your statutory rights.
                </p>
                <h3 className="text-2xl font-bold mb-4">e. Entire Agreement</h3>
                <p className="mb-8">
                    This Agreement is the final, complete and exclusive agreement of the parties with respect to the subject matter hereof and supersedes and merges all prior discussions between the parties with respect to such subject matter.
                </p>

                <h2 id="contact-information" className="text-3xl font-bold mb-4">19. Contact Information</h2>
                <p className="mb-8">
                    If you have any questions about this Agreement, please contact us at support@injsur.ai.
                </p>
            </div>
            <Footer />
        </div>
    );
};

export default TermsOfUse;
