import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { UseAuth } from './AuthContext';

const ProtectedRoute = ({ element }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const { token, isTokenExpired, refreshAccessToken } = UseAuth();

  useEffect(() => {
    const checkAuth = async () => {
      try {
        await Auth.currentAuthenticatedUser();
        setIsAuthenticated(true);
      } catch (error) {
        setIsAuthenticated(false);
      }
    };

    const verifyToken = async () => {
      if (token && isTokenExpired(token)) {
        try {
          await refreshAccessToken();
          checkAuth();
        } catch (error) {
          setIsAuthenticated(false);
        }
      } else {
        checkAuth();
      }
    };

    verifyToken();
  }, [token, isTokenExpired, refreshAccessToken]);

  if (isAuthenticated === null) {
    return null; // or a loading spinner
  }

  if (isAuthenticated) {
    return element;
  } else {
    return <Navigate to="/login" replace />;
  }
};

export default ProtectedRoute;
