import React, { useEffect, useState } from 'react';
import UserHeader from './UserHeader';
import FrailtyGames from './FrailtyGames';

const Games = () => {
    const [games, setGames] = useState([]); // State for game list
    const [selectedGame, setSelectedGame] = useState(null); // State for the selected game
    const [dateRange, setDateRange] = useState('');
    const [loading, setLoading] = useState(true); // Add loading state

    useEffect(() => {
        fetchGameList();
    }, []); // Empty dependency array ensures it runs once on mount

    const updateDates = () => {
        const today = new Date();
        const dayOfWeek = today.getUTCDay(); // Get the UTC day of the week

        // Set the start of the week to Tuesday (2 = Tuesday in UTC)
        let daysSinceTuesday = (dayOfWeek >= 2) ? dayOfWeek - 3 : 7 - (3 - dayOfWeek);

        const startOfWeek = new Date(Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), today.getUTCDate()));
        startOfWeek.setUTCDate(today.getUTCDate() - daysSinceTuesday); // Start of week in UTC (Tuesday)

        const endOfWeek = new Date(startOfWeek);
        endOfWeek.setUTCDate(startOfWeek.getUTCDate() + 6); // End of week in UTC (Monday)

        const formatDate = (date) => {
            const year = date.getUTCFullYear();
            const month = String(date.getUTCMonth() + 1).padStart(2, '0');
            const day = String(date.getUTCDate()).padStart(2, '0');
            return `${month}/${day}/${year}`;
        };

        return {
            fakeStartDate: formatDate(startOfWeek), // Tuesday (10/1) UTC
            fakeEndDate: formatDate(endOfWeek),     // Monday (10/7) UTC
        };
    };



    const fetchGameList = async () => {
        setLoading(true);
        const { fakeStartDate, fakeEndDate } = updateDates();
        const startDate = new Date(fakeStartDate);  // Starts from Tuesday (10/1 in this case)
        const endDate = new Date(fakeEndDate);      // Ends on Monday (10/7 in this case)

        const gamePromises = [];
        for (let date = new Date(startDate); date <= endDate; date.setDate(date.getDate() + 1)) {
            const dateString = date.toISOString().split('T')[0];
            console.log(`Fetching games for date: ${dateString}`); // Log to verify the correct date range
            gamePromises.push(getGamesByDate(dateString));
        }

        try {
            const gamesArray = await Promise.all(gamePromises);
            const games = gamesArray.flat();
            setGames(games);
            setLoading(false);

            const adjustedStartDate = new Date(startDate);
            adjustedStartDate.setDate(adjustedStartDate.getDate() - 1);

            const adjustedEndDate = new Date(endDate);
            adjustedEndDate.setDate(adjustedEndDate.getDate() - 1);

            const formattedStartDate = adjustedStartDate.toLocaleDateString();
            const formattedEndDate = adjustedEndDate.toLocaleDateString();

            setDateRange(`${formattedStartDate} - ${formattedEndDate}`);
        } catch (error) {
            console.error('Error fetching game list:', error);
            setLoading(false);
        }
    };




    const adjustToUserTimezoneForDate = (date, time) => {
        const dateTimeString = `${date}T${time}:00Z`; // Combine date and time in UTC
        const utcDate = new Date(dateTimeString); // Create a Date object in UTC

        // Use toLocaleDateString for the date and toLocaleTimeString for time (without seconds)
        const localDate = utcDate.toLocaleDateString();
        const localTime = utcDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });

        return `${localDate} ${localTime}`; // Combine the formatted date and time
    };




    const getGamesByDate = async (date) => {
        const leagueId = 1;
        const url = `https://api-american-football.p.rapidapi.com/games?date=${date}&league=${leagueId}`;
        const options = {
            method: 'GET',
            headers: {
                'X-RapidAPI-Key': '0be998d966mshcc7288b1c531576p19e5b0jsn08fc430b251e',
                'X-RapidAPI-Host': 'api-american-football.p.rapidapi.com',
            },
        };

        try {
            const response = await fetch(url, options);
            const data = await response.json();
            if (data.errors && data.errors.length > 0) {
                throw new Error(`Error fetching game list: ${data.errors[0]}`);
            }

            return data.response
                ? data.response.map((game) => {
                    const gameTime = game.game?.date?.time || '00:00';
                    const formattedTime = adjustToUserTimezone(gameTime);

                    // Adjust both the game date and time to the user's timezone
                    const localDateTime = adjustToUserTimezoneForDate(game.game.date.date, gameTime);

                    return {
                        id: game.game.id,
                        home_team: game.teams.home.name,
                        away_team: game.teams.away.name,
                        home_team_logo: game.teams.home.logo,
                        away_team_logo: game.teams.away.logo,
                        game_time: formattedTime,
                        game_date: localDateTime, // Use the local date and time
                    };
                })
                : [];
        } catch (error) {
            console.error('Error fetching games by date:', error);
            return [];
        }
    };


    const adjustToUserTimezone = (time) => {
        const date = new Date(`1970-01-01T${time}:00Z`);
        return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true }); // Format without seconds
    };



    const handleGameSelection = (game) => {
        setSelectedGame(game);
    };

    return (
        <div>
            <UserHeader />
            <h2 className="text-center mt-6 font-bold text-2xl mb-4">{dateRange}</h2>

            {loading ? (
                <div className="flex justify-center items-center">
                    <p>Loading...</p>
                </div>
            ) : !selectedGame ? (
                <div className="flex flex-col justify-center mb-5">
                    {Object.entries(games.reduce((acc, game) => {
                        const gameDate = game.game_date;
                        if (!acc[gameDate]) {
                            acc[gameDate] = [];
                        }
                        acc[gameDate].push(game);
                        return acc;

                    }, {})).map(([gameDateTime, gamesOnDate]) => {
                        return (
                            <div key={gameDateTime}>
                                {/* Display games grouped by date and time */}
                                <div className="px-4 ml-6 text-left w-full font-bold text-xl">{gameDateTime}</div>
                                <div className="flex flex-wrap justify-start mb-5">
                                    {gamesOnDate.map((game) => (
                                        <div
                                            key={game.id}
                                            onClick={() => handleGameSelection(game)}
                                            className="flex items-center justify-start ml-6 px-4 py-2 m-2.5 border border-gray-300 rounded-lg bg-zinc-900 text-white w-full sm:w-1/2 lg:w-1/3 cursor-pointer transition-all duration-100 hover:bg-green-700"
                                        >
                                            <img src={game.home_team_logo} alt={`${game.home_team} logo`} className="w-10 h-10 rounded-full mr-2" />
                                            <span className="text-center mr-2">{`${game.home_team} vs. ${game.away_team}`}</span>
                                            <img src={game.away_team_logo} alt={`${game.away_team} logo`} className="w-10 h-10 rounded-full" />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        );
                    })}




                </div>
            ) : (
                <>
                    <FrailtyGames
                        game={selectedGame}
                        onBack={() => setSelectedGame(null)}
                    />
                </>
            )}
        </div>
    );
};

export default Games;
