import React, { useState, useEffect } from 'react';

const CookieBanner = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const consent = getCookie('cookies_accepted');
    if (consent === null) {
      setIsVisible(true);
    }
  }, []);

  const setCookie = (name, value, days) => {
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    const expires = "expires=" + date.toUTCString();
    document.cookie = name + "=" + value + ";" + expires + ";path=/";
  };

  const getCookie = (name) => {
    const nameEQ = name + "=";
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      let c = cookies[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  };

  const acceptCookies = () => {
    setCookie('cookies_accepted', 'true', 365);
    setIsVisible(false);
    // Call any function to enable cookies here
  };

  const rejectCookies = () => {
    setCookie('cookies_accepted', 'false', 365);
    setIsVisible(false);
    // Call any function to disable cookies here
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-white text-black p-4 shadow-md z-50">
      <div className="container mx-auto flex justify-between items-center">
        <p className="text-sm">
          We use cookies to enhance user experience and to analyze performance and traffic on our website. We also share information about your use of our site with advertising and analytics partners. <a href="/privacy-policy" className="underline">Privacy Policy</a>.
        </p>
        <div className="flex space-x-4">
          <button onClick={acceptCookies} className="bg-green-500 text-white px-4 py-2 rounded">Accept</button>
          <button onClick={rejectCookies} className="bg-gray-500 text-white px-4 py-2 rounded">Reject</button>
        </div>
      </div>
    </div>
  );
};

export default CookieBanner;
