import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const ResetPassword = () => {
    const [email, setEmail] = useState('');
    const [confirmationCode, setConfirmationCode] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [stage, setStage] = useState(0); // 0: request code, 1: enter code & new password
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();

    const requestPasswordReset = async () => {
        try {
            const response = await fetch('https://bb2b9p5bpk.execute-api.us-west-1.amazonaws.com/prod/sendResetCode', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email })
            });

            if (response.ok) {
                const data = await response.json();
                console.log(data.message); // Log success message
                setStage(1); // Move to the next stage where the user enters the code and new password
            } else {
                const errorData = await response.json();
                setErrorMessage(errorData.message || 'An error occurred.');
            }
        } catch (error) {
            console.error('Error sending confirmation code:', error);
            setErrorMessage(error.message || 'An error occurred.');
        }
    };


    const resetPassword = async () => {
        console.log('resetPassword function triggered');
        try {
            const response = await fetch('https://bb2b9p5bpk.execute-api.us-west-1.amazonaws.com/prod/verifyResetPassword', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    email,
                    confirmationCode,
                    newPassword
                })
            });
    
            const data = await response.json();
            if (response.ok) {
                toast.success('Password reset successfully!', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                setTimeout(() => {
                    navigate('/login');
                }, 3200);
            } else {
                setErrorMessage(data.message || 'An error occurred.');
            }
        } catch (error) {
            console.error('Error resetting password:', error);
            setErrorMessage(error.message || 'An error occurred.');
        }
    };
    
    

    return (
        <div className="flex min-h-screen justify-center items-center bg-gray-100">
            <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md">
                <h2 className="text-2xl mb-4 text-center">Reset Password</h2>
                {errorMessage && <div className="text-red-500 mb-4 text-center">{errorMessage}</div>}
                {stage === 0 ? (
                    <>
                        <p className="mb-4 text-center">Enter your email to receive a password reset code.</p>
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2">Email</label>
                            <input
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                type="text"
                                placeholder="Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        <button
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full"
                            onClick={requestPasswordReset}
                        >
                            Request reset code
                        </button>
                    </>
                ) : (
                    <>
                        <p className="mb-4 text-center">Enter the code you received in your email and your new password.</p>
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2">Confirmation Code</label>
                            <input
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                type="text"
                                placeholder="Confirmation code"
                                value={confirmationCode}
                                onChange={(e) => setConfirmationCode(e.target.value)}
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2">New Password</label>
                            <input
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                type="password"
                                placeholder="New password"
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                            />
                        </div>
                        <button
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full"

                            onClick={(e) => {
                                e.preventDefault();
                                resetPassword();
                            }}
                        >
                            Reset Password
                        </button>
                    </>
                )}
                <p className="mt-4 text-center">
                    Remembered? <Link to="/login" className="text-blue-500">Back to Sign in</Link>
                </p>
            </div>
        </div>
    );
};

export default ResetPassword;
