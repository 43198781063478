// src/modelConfig.js
export const modelConfig = {
    "modelTopology": {
        "class_name": "Sequential",
        "config": {
            "name": "sequential_1",
            "layers": [
                {
                    "class_name": "Dense",
                    "config": {
                        "units": 1,
                        "activation": "sigmoid",
                        "use_bias": true,
                        "kernel_initializer": {
                            "class_name": "VarianceScaling",
                            "config": {
                                "scale": 1,
                                "mode": "fan_avg",
                                "distribution": "normal",
                                "seed": null
                            }
                        },
                        "bias_initializer": {
                            "class_name": "Zeros",
                            "config": {}
                        },
                        "kernel_regularizer": null,
                        "bias_regularizer": null,
                        "activity_regularizer": null,
                        "kernel_constraint": null,
                        "bias_constraint": null,
                        "name": "dense_Dense1",
                        "trainable": true,
                        "batch_input_shape": [null, 12],
                        "dtype": "float32"
                    }
                }
            ]
        },
        "keras_version": "tfjs-layers 4.0.0",
        "backend": "tensor_flow.js"
    },
    "format": "layers-model",
    "generatedBy": "TensorFlow.js tfjs-layers v4.0.0",
    "convertedBy": null,
    "weightsManifest": [
        {
            "paths": ["./injsur.weights.bin"],
            "weights": [
                {
                    "name": "dense_Dense1/kernel",
                    "shape": [12, 1],
                    "dtype": "float32"
                },
                {
                    "name": "dense_Dense1/bias",
                    "shape": [1],
                    "dtype": "float32"
                }
            ]
        }
    ]
};
