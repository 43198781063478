import React, { useEffect, useState } from 'react';
import 'flatpickr/dist/flatpickr.min.css';
import UserHeader from './UserHeader';

const GetInsights = ({ model, predictFrailty, calculateAdjustedFrailtyScore }) => {
    const [displayedPlayers, setDisplayedPlayers] = useState(new Set());
    const [selectedGame, setSelectedGame] = useState(null);
    const [players, setPlayers] = useState([]);
    const [odds, setOdds] = useState([]);
    const [teams, setTeams] = useState([]);
    const [popupContent, setPopupContent] = useState(null);
    const [showSpinner, setShowSpinner] = useState(false);
    const [dateRange, setDateRange] = useState('');

    useEffect(() => {
        fetchGameList();
        fetchAndDisplayTeams();
    }, []);

    const displayPlayerStats = (container, stats) => {
        container.innerHTML = '';

        Object.entries(stats).forEach(([statName, statValue]) => {
            const statElement = document.createElement('p');
            statElement.textContent = `${statName}: ${statValue}`;
            container.appendChild(statElement);
        });
    };

    const fetchGameOdds = async (gameId) => {
        const url = `https://api-american-football.p.rapidapi.com/odds?game=${gameId}`;
        const options = {
            method: 'GET',
            headers: {
                'x-rapidapi-key': '0be998d966mshcc7288b1c531576p19e5b0jsn08fc430b251e',
                'x-rapidapi-host': 'api-american-football.p.rapidapi.com'
            }
        };

        try {
            const response = await fetch(url, options);
            const result = await response.json();
            return result;
        } catch (error) {
            console.error('Error fetching game odds:', error);
            return null;
        }
    };

    const displayGameOdds = (oddsContainer, odds) => {
        oddsContainer.innerHTML = '';
        if (odds && odds.length > 0) {
            const oddsTitle = document.createElement('h4');
            oddsTitle.textContent = 'Odds:';
            oddsContainer.appendChild(oddsTitle);

            odds.forEach(odd => {
                const oddItem = document.createElement('p');
                oddItem.textContent = `Bookmaker: ${odd.bookmaker} - Odds: ${odd.odds}`;
                oddsContainer.appendChild(oddItem);
            });
        } else {
            oddsContainer.innerHTML = '<p>No odds available</p>';
        }
    };



    const fetchGameList = async () => {
        const { fakeStartDate, fakeEndDate } = updateDates();
        const startDate = new Date(fakeStartDate);
        const endDate = new Date(fakeEndDate);

        const gamePromises = [];
        for (let date = new Date(startDate); date <= endDate; date.setDate(date.getDate() + 1)) {
            const dateString = date.toISOString().split('T')[0];
            gamePromises.push(getGamesByDate(dateString));
        }

        try {
            const gamesArray = await Promise.all(gamePromises);
            const games = gamesArray.flat();
            setSelectedGame(games);

            // Format and set the date range
            const formattedStartDate = new Date(fakeStartDate).toLocaleDateString();
            const formattedEndDate = new Date(fakeEndDate).toLocaleDateString();
            setDateRange(`${formattedStartDate} - ${formattedEndDate}`);
        } catch (error) {
            console.error('Error fetching game list:', error);
            setPopupContent(`Error fetching game list: ${error.message}`);
        }
    };


    const getGamesByDate = async (date) => {
        const leagueId = 1;
        const url = `https://api-american-football.p.rapidapi.com/games?date=${date}&league=${leagueId}`;
        const options = {
            method: 'GET',
            headers: {
                'X-RapidAPI-Key': '0be998d966mshcc7288b1c531576p19e5b0jsn08fc430b251e',
                'X-RapidAPI-Host': 'api-american-football.p.rapidapi.com'
            }
        };

        const response = await fetch(url, options);
        const data = await response.json();
        if (data.errors && data.errors.length > 0) {
            throw new Error(`Error fetching game list: ${data.errors[0]}`);
        }
        return data.response ? data.response.map(game => ({
            id: game.game.id,
            home_team: game.teams.home.name,
            away_team: game.teams.away.name,
            home_team_id: game.teams.home.id,
            away_team_id: game.teams.away.id,
            home_team_logo: game.teams.home.logo,
            away_team_logo: game.teams.away.logo
        })) : [];
    };

    const fetchAndDisplayTeams = async () => {
        const { fakeStartDate, fakeEndDate } = updateDates(); // Ensure date range is recalculated
        setDateRange(`${fakeStartDate} - ${fakeEndDate}`); // Update the date range state

        const apiUrl = 'https://api-american-football.p.rapidapi.com/standings?league=1&season=2023';
        const options = {
            method: 'GET',
            headers: {
                'x-rapidapi-key': '0be998d966mshcc7288b1c531576p19e5b0jsn08fc430b251e',
                'x-rapidapi-host': 'api-american-football.p.rapidapi.com'
            }
        };

        try {
            const response = await fetch(apiUrl, options);
            const result = await response.json();
            const sortedTeams = result.response.sort((a, b) => a.position - b.position);
            setTeams(sortedTeams);
        } catch (error) {
            console.error('Error fetching teams:', error);
        }
    };

    const fetchGamesForTeam = async (teamId) => {
        const season = 2024;
        const url = `https://api-american-football.p.rapidapi.com/games?team=${teamId}&season=${season}`;
        const options = {
            method: 'GET',
            headers: {
                'X-RapidAPI-Key': '0be998d966mshcc7288b1c531576p19e5b0jsn08fc430b251e',
                'X-RapidAPI-Host': 'api-american-football.p.rapidapi.com'
            }
        };

        try {
            const response = await fetch(url, options);
            const data = await response.json();
            const games = data.response ? data.response : [];

            // Filter games based on the dateRange
            const dateRangeParts = dateRange.split(' - ');
            const startDate = new Date(dateRangeParts[0]);
            const endDate = new Date(dateRangeParts[1]);
            console.log(startDate, endDate)
            const filteredGames = games.filter(game => {
                const gameDate = new Date(game.game.date.date);
                return gameDate >= startDate && gameDate <= endDate;
            });

            return filteredGames;
        } catch (error) {
            console.error('Error fetching games for team:', error);
            return [];
        }
    };




    const fetchAndDisplayPlayers = async (gameId, teamId, teamType) => {
        setShowSpinner(true);
        setPlayers([]);

        try {
            let finalGameId = gameId;

            // If gameId is null, fetch the corresponding game for the team
            if (!gameId) {
                const teamGames = await fetchGamesForTeam(teamId);
                if (teamGames && teamGames.length > 0) {
                    finalGameId = teamGames[0].game.id; // Use the first game ID found
                } else {
                    console.error('No games found for this team.');
                    setShowSpinner(false);
                    return;
                }
            }

            if (!finalGameId) {
                console.error('No valid game ID found.');
                setShowSpinner(false);
                return;
            }

            const url = `https://api-american-football.p.rapidapi.com/games/statistics/players?id=${finalGameId}&team=${teamId}`;
            const options = {
                method: 'GET',
                headers: {
                    'X-RapidAPI-Key': '0be998d966mshcc7288b1c531576p19e5b0jsn08fc430b251e',
                    'X-RapidAPI-Host': 'api-american-football.p.rapidapi.com'
                }
            };

            const response = await fetch(url, options);
            const data = await response.json();
            if (data.results > 0) {
                const promises = data.response[0].groups.flatMap(group =>
                    group.players.map(player =>
                        fetchPlayerDetailsAndStats(player, teamId, teamType)
                    )
                );
                await Promise.all(promises);
            } else {
                console.error('No player statistics found for this game.');
            }
        } catch (error) {
            console.error('Error fetching player statistics:', error);
        } finally {
            setShowSpinner(false);
        }
    };



    const fetchPlayerDetailsAndStats = async (player, teamId, teamType) => {
        const playerId = player.player.id;
        if (!displayedPlayers.has(playerId)) {
            displayedPlayers.add(playerId);

            const playerDetails = await fetchPlayerDetails(player);
            if (playerDetails && isOffensivePlayer(playerDetails.position)) {
                const teamName = await fetchTeamName(teamId);
                const teamLogo = await fetchTeamLogo(teamId);
                const playerStats = await fetchPlayerStatistics(player, teamId);
                console.log("playerStats", playerStats)
                const frailtyScore = await predictFrailty(playerDetails, playerStats);
                console.log("frailtyScore", frailtyScore)
                const fullData = { player: playerDetails, stats: playerStats, frailtyScore, teamType: teamType, teamName: teamName, teamLogo: teamLogo };  // Added frailtyScore here
                setPlayers(prevPlayers => [...prevPlayers, fullData]);
            }
        }
    };


    const fetchPlayerDetails = async (player) => {
        const url = `https://api-american-football.p.rapidapi.com/players?id=${player.player.id}`;
        const options = {
            method: 'GET',
            headers: {
                'X-RapidAPI-Key': '0be998d966mshcc7288b1c531576p19e5b0jsn08fc430b251e',
                'X-RapidAPI-Host': 'api-american-football.p.rapidapi.com'
            }
        };

        try {
            const response = await fetch(url, options);
            if (!response.ok) {
                throw new Error(`API call failed with status: ${response.status}`);
            }
            const playerDetailsData = await response.json();
            return playerDetailsData.response && playerDetailsData.response.length > 0 ? playerDetailsData.response[0] : null;
        } catch (error) {
            console.error('Error fetching player details:', error);
            return null;
        }
    };

    const fetchTeamName = async (teamId) => {
        const url = `https://api-american-football.p.rapidapi.com/teams?id=${teamId}`;
        const options = {
            method: 'GET',
            headers: {
                'X-RapidAPI-Key': '0be998d966mshcc7288b1c531576p19e5b0jsn08fc430b251e',
                'X-RapidAPI-Host': 'api-american-football.p.rapidapi.com'
            }
        };

        try {
            const response = await fetch(url, options);
            if (!response.ok) {
                throw new Error(`API call failed with status: ${response.status}`);
            }
            const teamData = await response.json();
            return teamData.response && teamData.response.length > 0 ? teamData.response[0].name : null;
        } catch (error) {
            console.error('Error fetching team name:', error);
            return null;
        }
    };

    const fetchTeamLogo = async (teamId) => {
        const url = `https://api-american-football.p.rapidapi.com/teams?id=${teamId}`;
        const options = {
            method: 'GET',
            headers: {
                'X-RapidAPI-Key': '0be998d966mshcc7288b1c531576p19e5b0jsn08fc430b251e',
                'X-RapidAPI-Host': 'api-american-football.p.rapidapi.com'
            }
        };

        try {
            const response = await fetch(url, options);
            if (!response.ok) {
                throw new Error(`API call failed with status: ${response.status}`);
            }
            const teamData = await response.json();
            return teamData.response && teamData.response.length > 0 ? teamData.response[0].logo : null;
        } catch (error) {
            console.error('Error fetching team logo:', error);
            return null;
        }
    };

    const fetchPlayerStatistics = async (player, teamId) => {
        const season = 2024;
        const url = `https://api-american-football.p.rapidapi.com/players/statistics?season=${season}&id=${player.player.id}`;
        const options = {
            method: 'GET',
            headers: {
                'X-RapidAPI-Key': '0be998d966mshcc7288b1c531576p19e5b0jsn08fc430b251e',
                'X-RapidAPI-Host': 'api-american-football.p.rapidapi.com'
            }
        };

        try {
            const response = await fetch(url, options);
            if (!response.ok) {
                throw new Error(`API call failed with status: ${response.status}`);
            }
            const statsData = await response.json();
            return mapAndNormalizePlayerStatistics(statsData);
        } catch (error) {
            console.error('Error fetching player statistics:', error);
            return null;
        }
    };

    const fetchBackStatistics = async (player) => {
        const season = 2023;
        const url = `https://api-american-football.p.rapidapi.com/players/statistics?season=${season}&id=${player.id}`;
        const options = {
            method: 'GET',
            headers: {
                'X-RapidAPI-Key': '0be998d966mshcc7288b1c531576p19e5b0jsn08fc430b251e',
                'X-RapidAPI-Host': 'api-american-football.p.rapidapi.com'
            }
        };

        try {
            const response = await fetch(url, options);
            if (!response.ok) {
                throw new Error(`API call failed with status: ${response.status}`);
            }
            const statsData = await response.json();
            return parseBackStatistics(statsData);
        } catch (error) {
            console.error('Error fetching player statistics:', error);
            return null;
        }
    };


    const parseBackStatistics = (statsData) => {
        const stats = {};

        statsData.response.forEach(team => {
            team.teams.forEach(teamDetail => {
                teamDetail.groups.forEach(group => {
                    group.statistics.forEach(stat => {
                        if (stat.value !== null && stat.value !== undefined) {
                            stats[stat.name] = stat.value;
                        }
                    });
                });
            });
        });

        return stats;
    };

    const parseStatistics = (statsData) => {
        const stats = {};
        statsData.response.forEach(team => {
            team.teams.forEach(teamDetail => {
                teamDetail.groups.forEach(group => {
                    group.statistics.forEach(stat => {
                        if (stat.value !== null && stat.value !== undefined) {
                            stats[stat.name] = stat.value;
                        }
                    });
                });
            });
        });
        return stats;
    };

    const mapAndNormalizePlayerStatistics = (statsData) => {
        const stats = parseStatistics(statsData);
        const mappedAndNormalizedStats = {
            rush_attempts: mapAndNormalize(stats.rush_attempts, 'rush_attempts'),
            rush_yards: mapAndNormalize(stats.rush_yards, 'rush_yards'),
            rush_touchdowns: mapAndNormalize(stats.rush_touchdowns, 'rush_touchdowns'),
            pass_attempts: mapAndNormalize(stats.pass_attempts, 'pass_attempts'),
            pass_completions: mapAndNormalize(stats.pass_completions, 'pass_completions'),
            pass_yards: mapAndNormalize(stats.pass_yards, 'pass_yards'),
            pass_touchdowns: mapAndNormalize(stats.pass_touchdowns, 'pass_touchdowns'),
            interceptions: mapAndNormalize(stats.interceptions, 'interceptions'),
            receptions: mapAndNormalize(stats.receptions, 'receptions'),
            receiving_yards: mapAndNormalize(stats.receiving_yards, 'receiving_yards'),
            receiving_touchdowns: mapAndNormalize(stats.receiving_touchdowns, 'receiving_touchdowns'),
            fumbles_lost: mapAndNormalize(stats.fumbles_lost, 'fumbles_lost')
        };

        return mappedAndNormalizedStats;
    };



    const toggleDisplay = (option) => {
        if (option === 'games') {
            setPlayers([]);
            setSelectedGame(null);
            fetchGameList();
        } else if (option === 'teams') {
            setPlayers([]);
            setSelectedGame(null);
            fetchAndDisplayTeams();
        }
    };

    // Function to update the date range
    const updateDates = () => {
        const today = new Date();

        // Calculate the difference from the previous Tuesday
        const dayOfWeek = today.getDay(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday
        let daysSinceTuesday;

        if (dayOfWeek >= 2) {
            daysSinceTuesday = dayOfWeek - 2; // Tuesday has index 2
        } else {
            daysSinceTuesday = 7 - (2 - dayOfWeek); // If today is Sunday or Monday, go back to previous Tuesday
        }

        const startOfWeek = new Date(today);
        startOfWeek.setDate(today.getDate() - daysSinceTuesday - 21); // Adjust to start from last week's Tuesday

        // Calculate the Monday of the previous week
        const endOfWeek = new Date(startOfWeek);
        endOfWeek.setDate(startOfWeek.getDate() + 6); // 6 days from last week's Tuesday is last week's Monday

        const formatDate = (date) => {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            return `${month}/${day}/${year}`;
        };

        const fakeStartDate = formatDate(startOfWeek);
        const fakeEndDate = formatDate(endOfWeek);

        return { fakeStartDate, fakeEndDate };
    };




    // Function to check if the player's position is offensive
    const isOffensivePlayer = (position) => {
        return ['QB', 'RB', 'WR', 'TE'].includes(position);
    };

    // Function to map and normalize player statistics
    const mapAndNormalize = (value, name) => {
        const mapping = {
            rush_attempts: 100,
            rush_yards: 500,
            rush_touchdowns: 10,
            pass_attempts: 100,
            pass_completions: 100,
            pass_yards: 1000,
            pass_touchdowns: 10,
            interceptions: 5,
            receptions: 50,
            receiving_yards: 500,
            receiving_touchdowns: 10,
            fumbles_lost: 5
        };

        const normalizedValue = parseFloat((value / mapping[name]).toFixed(2));

        return isNaN(normalizedValue) ? 0 : normalizedValue;
    };


    return (
        <div>
            <UserHeader />
            <div className="flex justify-between max-w-full w-full m-0 p-0 relative">
                <div className="w-64 p-5 pb-25 bg-white flex flex-col gap-2 fixed top-0 bottom-0 overflow-y-auto left-0">
                    <h3>
                        <span id="infoIconInjury"></span>
                    </h3>
                    <h3>
                        <span id="infoIconStats"></span>
                    </h3>
                </div>

                <div className="flex flex-col min-h-screen flex-1">
                    <div className="flex-1 p-5 text-center bg-[url('data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 1440 320\'%3E%3C/path fill=\'%23424242\' fill-opacity=\'1\' d=\'M0,224L1440,32L1440,320L0,320Z\'%3E%3C/svg%3E')] bg-no-repeat bg-cover bg-center relative mx-[250px] overflow-y-auto">
                        <div className="hero-section flex items-center justify-center text-center text-2xl bg-white text-black h-[calc(30vh+20px)] overflow-hidden relative -top-5">
                            <div className="relative z-10 w-full p-5">
                                <h1 className="font-arial text-4xl mb-2.5">INJSUR.AI</h1>
                                <h2 className="text-1.5xl">AI Frailty Scores</h2>
                                <div className="absolute top-5 right-5 z-100">
                                    <button onClick={() => setPopupContent('How Frailty Scores Work')} className="bg-[#080a54] border border-white text-white py-2 px-5 text-right inline-block text-sm cursor-pointer rounded transition-all duration-300 hover:bg-white hover:text-[#080a54]">
                                        How Frailty Scores Work
                                    </button>
                                </div>
                                {popupContent && (
                                    <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-[#080a54] text-white z-1000 p-5 rounded shadow-lg text-center">
                                        <div className="relative max-w-md mx-auto text-left">
                                            <span onClick={() => setPopupContent(null)} className="absolute top-2.5 right-2.5 cursor-pointer text-1.5xl">&times;</span>
                                            <h2>How Frailty Scores Work</h2>
                                            <p>{popupContent}</p>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div id="message"></div>

                        <div className="text-center mb-5">
                            <button onClick={() => toggleDisplay('games')} className="bg-[#080a54] border border-gray-300 text-white py-2 px-5 text-center inline-block text-lg cursor-pointer rounded transition-all duration-300 hover:bg-blue-900">
                                Games
                            </button>
                            <button onClick={() => toggleDisplay('teams')} className="bg-[#080a54] border border-gray-300 text-white py-2 px-5 text-center inline-block text-lg cursor-pointer rounded transition-all duration-300 hover:bg-blue-900">
                                Teams
                            </button>
                        </div>

                        <h2 id="gameListTitle" className="text-center">{dateRange}</h2>
                        <div id="gameList" className="flex flex-wrap justify-center mb-5">
                            {selectedGame && selectedGame.map((game) => (
                                <div key={game.id} onClick={() => fetchAndDisplayPlayers(game.id, game.home_team_id, game.away_team_id)} className="flex items-center justify-center p-2.5 m-2.5 border border-gray-300 rounded-lg bg-gray-800 text-white w-75 cursor-pointer transition-all duration-100 hover:bg-blue-900">
                                    <img src={game.home_team_logo} alt={`${game.home_team} logo`} className="w-10 h-10 rounded-full" />
                                    <span className="text-center">{`${game.home_team} vs. ${game.away_team}`}</span>
                                    <img src={game.away_team_logo} alt={`${game.away_team} logo`} className="w-10 h-10 rounded-full" />
                                </div>
                            ))}
                        </div>

                        <h2 id="teamListTitle" className="text-center">NFL Teams for the 2024 Season</h2>
                        <div id="teams-container" className="flex flex-wrap justify-center mt-5">
                            {teams.map((team) => (
                                <div key={team.team.id} onClick={() => fetchAndDisplayPlayers(null, team.team.id, 'offense')} className="relative border border-gray-300 rounded-xl m-1 w-64 h-[calc(250px*1.4)] overflow-hidden bg-[#01022b] text-white shadow-lg perspective-[1000px] bg-center bg-no-repeat bg-cover" style={{ backgroundImage: `url(${team.team.logo})`, backgroundSize: '85% auto' }}
                                >
                                    <span className="hidden">{team.team.name}</span>
                                </div>
                            ))}
                        </div>

                        <div id="output"></div>

                        <div id="playerContainer" className="flex flex-wrap justify-around">
                            {players.map((playerData, index) => (
                                <div key={index} className="relative border border-gray-300 rounded-xl m-1 w-64 h-[calc(250px*1.4)] overflow-hidden bg-[#01022b] text-white shadow-lg perspective-[1000px]">
                                    <div className="relative w-full h-full text-center transition-transform duration-400 transform-style-preserve-3d">
                                        <div className="absolute w-full h-full flex flex-col p-5 backface-hidden">
                                            <img src={playerData.teamLogo}
                                                alt={`${playerData.teamName} logo`}
                                                className="absolute top-2 left-2 w-10 h-10 rounded-full z-10" />

                                            <div className={`absolute top-2.5 right-2.5 bg-black bg-opacity-50 p-1 rounded-md text-xl z-10 ${calculateAdjustedFrailtyScore(playerData.frailtyScore, playerData.player.position) > 7.5 ? 'text-red-500' : calculateAdjustedFrailtyScore(playerData.frailtyScore, playerData.player.position) < 6 ? 'text-green-500' : 'text-yellow-500'}`}>
                                                Frailty Score: {calculateAdjustedFrailtyScore(playerData.frailtyScore, playerData.player.position).toFixed(1)}
                                            </div>

                                            <div className="flex-grow flex flex-col justify-between p-5">
                                                <div className="text-center">
                                                    <img src={playerData.player.image} alt={playerData.player.name} className="w-full h-auto rounded-lg mx-auto" />
                                                </div>
                                                <h3 className="text-2xl my-2.5 text-center w-full">{playerData.player.name}</h3>
                                                <div className="text-center text-gray-400">{playerData.player.position}</div>
                                            </div>
                                        </div>

                                        <div className="absolute w-full h-full flex flex-col p-5 backface-hidden transform rotate-y-180">
                                            <div className="absolute top-2.5 right-2.5 bg-transparent p-1 rounded-md text-xl z-10">
                                                {/* Frailty Score: {calculateAdjustedFrailtyScore(playerData.frailtyScore, playerData.player.position).toFixed(2)} */}
                                            </div>
                                            <div className="flex-grow overflow-y-auto p-1 text-gray-500" id={`backPlayerStats-${index}`}>
                                                {/* Display player statistics here */}
                                            </div>
                                            <div className="text-center" id={`oddsContainer-${index}`}>
                                                {/* Display game odds here */}
                                            </div>
                                        </div>
                                    </div>

                                    {/* Add click event to handle flip and fetch additional stats */}
                                    <div onClick={async () => {
                                        const backPlayerStats = document.getElementById(`backPlayerStats-${index}`);
                                        const oddsContainer = document.getElementById(`oddsContainer-${index}`);
                                        if (!backPlayerStats.dataset.statsFetched) {
                                            try {
                                                const stats = await fetchBackStatistics(playerData.player);
                                                // Use a method to display the stats, similar to displayPlayerStats
                                                displayPlayerStats(backPlayerStats, stats);
                                                backPlayerStats.dataset.statsFetched = true;

                                                // Fetch and display odds
                                                const gameId = playerData.gameId;
                                                const odds = await fetchGameOdds(gameId);
                                                displayGameOdds(oddsContainer, odds);
                                            } catch (error) {
                                                console.error('Error fetching player statistics:', error);
                                                backPlayerStats.innerHTML = '<p>Error fetching statistics</p>';
                                            }
                                        }
                                    }}>
                                        {/* Include the visual flipping interaction */}
                                    </div>
                                </div>
                            ))}
                        </div>


                        {showSpinner && (
                            <div className="w-[150px] h-[150px] fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 border-8 border-gray-300 border-t-white rounded-full animate-spin z-1000"></div>
                        )}
                    </div>

                    <footer id="footer" className="bg-white text-black text-center p-5 box-border">
                        <div className="max-w-screen-xl mx-auto">
                            <p className="m-0 text-sm">&copy; 2024 INJSUR.AI. All rights reserved. Patent Pending.</p>
                        </div>
                    </footer>
                </div>

                <div className="w-64 p-5 pb-25 bg-white flex flex-col gap-2 fixed top-0 bottom-0 overflow-y-auto right-0">
                    <h3>
                        <span id="infoIconField"></span>
                    </h3>
                    <h3>
                        <span id="infoIconInfluence"></span>
                    </h3>
                </div>
            </div>
        </div>
    );
};

export default GetInsights;
