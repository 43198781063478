import React, { useState } from 'react';
import imgbg from '../images/cardbg.jpg'; // Import the background image
import logo from '../images/injsurlogo2.png'; // Adjust the path based on your project structure

const PlayerCard = ({ playerId, teamName, frailtyScore, playerName, position,careerTouches, playerStats2023, playerStats2024, injury, injuryStatus,age, height, weight, experience, preExistingInjuries, missedGamePercentage, missedGamePercentageThree }) => {
    const [flipped, setFlipped] = useState(false);
    const [selectedYear, setSelectedYear] = useState(2024); // State to manage year tab
console.log("injuryStatus",injuryStatus)
    const handleCardClick = () => {
        setFlipped(!flipped);
    };

    const getScoreColor = (score) => {
        return score > 7 ? 'bg-red-500' : score < 3 ? 'bg-green-500' : 'bg-yellow-500';
    };

    // Dynamically construct the image path based on the player ID
    let playerImagePath;
    try {
        playerImagePath = require(`../player_images_id/${playerId}.png`);
    } catch (error) {
        playerImagePath = require(`../player_images_id/9405.png`);
    }

    // Decide which stats to show based on the selected year
    const displayedStats = selectedYear === 2024 ? playerStats2024 : playerStats2023;

    // Function to render QB-specific stats
    const renderQuarterbackStats = () => (
        <>
            <div className="flex justify-between text-black">
                <span>Pass Att/Comp</span>
                <span>{displayedStats?.pass_attempts || 0}/{displayedStats?.pass_completions || 0}</span>
            </div>

            <div className="flex justify-between text-black">
                <span>Pass Yds/TDs</span>
                <span>{displayedStats?.pass_yards || 0}/{displayedStats?.pass_touchdowns || 0}</span>
            </div>

            <div className="flex justify-between text-black">
                <span>Int/Sacks</span>
                <span>{displayedStats?.interceptions || 0}/{displayedStats?.sacks || 0}</span>
            </div>


            <div className="flex justify-between text-black">
                <span>Rush Att.</span>
                <span>{displayedStats?.rush_attempts || 0}</span>
            </div>

            <div className="flex justify-between text-black">
                <span>Rush Yds/TDs</span>
                <span>{displayedStats?.rush_yards || 0}/{displayedStats?.rush_touchdowns || 0}</span>
            </div>

            <div className="flex justify-between text-black">
                <span>Fumbles</span>
                <span>{displayedStats?.fumbles_lost || 0}</span>
            </div>
        </>
    );

    // Function to render RB/WR/TE stats
    const renderNonQuarterbackStats = () => (
        <>
            <div className="flex justify-between text-black">
                <span>Rush Att.</span>
                <span>{displayedStats?.rush_attempts || 0}</span>
            </div>

            <div className="flex justify-between text-black">
                <span>Rush Yds/TDs</span>
                <span>{displayedStats?.rush_yards || 0}/{displayedStats?.rush_touchdowns || 0}</span>
            </div>

            <div className="flex justify-between text-black">
                <span>Receptions</span>
                <span>{displayedStats?.receptions || 0}</span>
            </div>

            <div className="flex justify-between text-black">
                <span>Rec Yds/TDs</span>
                <span>{displayedStats?.receiving_yards || 0}/{displayedStats?.receiving_touchdowns || 0}</span>
            </div>

            <div className="flex justify-between text-black">
                <span>Fumbles</span>
                <span>{displayedStats?.fumbles_lost || 0}</span>
            </div>
        </>
    );
    // console.log("Injury status for player:", injury);
    // console.log("Frailty score for player:", frailtyScore);
    return (
        <div
            className="relative w-72 h-96 cursor-pointer"
            onClick={handleCardClick}
            style={{
                perspective: '1000px',
            }}
        >
            {/* Front of the Card */}
            <div
                className={`absolute w-full h-full bg-white rounded-lg shadow-2xl transform transition-transform duration-500 ${flipped ? 'rotate-y-180' : 'rotate-y-0'}`}
                style={{
                    backgroundImage: `url(${imgbg})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    transformStyle: 'preserve-3d',
                    backfaceVisibility: 'hidden',
                    transform: flipped ? 'rotateY(180deg)' : 'rotateY(0deg)',
                }}
            >
                <div className="flex justify-between p-1 bg-green-600 text-white rounded-t-lg">
                    <div className="font-bold text-[14px] ml-1 mt-1">{teamName}</div>
                    <div className="flex items-center justify-end w-auto h-auto">
                        <img src={logo} alt="Team Logo" className="w-auto h-7 object-contain" />
                    </div>
                </div>

                <div className="h-44 mt-2 flex items-center justify-center">
                    <img
                        src={playerImagePath}  // Use local image based on playerId or fallback
                        alt={playerName || 'Player'}
                        className="max-w-full max-h-full object-contain"  // Ensures the image doesn't overflow the container
                    />
                </div>

                {/* Frailty score box with colors */}
                <div
                    className={`flex justify-between items-center ml-2 mr-2 p-1 font-bold text-md  ${getScoreColor(frailtyScore)}`}
                >
                    <span>FRAILTY SCORE:</span>
                    <span className="ml-2 mr-2">{typeof frailtyScore === 'number' ? frailtyScore.toFixed(2) : frailtyScore}</span>
                </div>

                {/* Injury status, moved below the frailty score */}
                {injuryStatus.injury_status && (
                    <div className={`flex justify-center items-center mt-1 ${injuryStatus.injury_status === 'Questionable' ? 'bg-black text-yellow-400' : 'bg-black text-white'} mx-2 `}>
                        <span className="text-center font-bold">
                            {injuryStatus.injury_status === 'Questionable' ? 'QUESTIONABLE' : 'INJURED'}
                        </span>
                    </div>
                )}







                <div className="flex justify-between p-1 text-green-700 font-bold text-lg mx-2">
                    <div className="font-bold">{playerName}</div>
                    <div className="font-bold">{position}</div>
                </div>
            </div>

            {/* Back of the Card - Display Consolidated Player Statistics */}
            <div
                className={`absolute w-full h-full bg-gray-800 text-white rounded-lg shadow-lg transform transition-transform duration-500 ${flipped ? 'rotate-y-0' : '-rotate-y-180'}`}
                style={{
                    backgroundImage: `url(${imgbg})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    transformStyle: 'preserve-3d',
                    backfaceVisibility: 'hidden',
                    transform: flipped ? 'rotateY(0deg)' : 'rotateY(-180deg)',
                }}
            >

                <div className="flex justify-between p-1 bg-green-600 text-white rounded-t-lg">
                    <div className="font-bold text-sm ml-2 mt-1 truncate">{teamName}</div>
                    <div className="flex items-center justify-end w-auto h-auto">
                        <img src={logo} alt="Team Logo" className="w-auto h-6 object-contain" />
                    </div>
                </div>

                <div className="flex justify-between p-0.5 text-green-700 font-bold text-sm mx-2 border-b border-gray-400">
                    <div className="truncate">{playerName}</div>
                    <div className="truncate">{position}</div>
                </div>

                {/* Consolidated Player Information */}
                {/* Consolidated Player Information */}
                <div className="flex flex-col justify-start h-full px-2 py-1 text-sm">
                    <>
                        {/* Additional Player Information */}
                        <div className="flex space-x-2 font-bold">
                            <div className="text-black">Age: {age}</div>
                            <div className="text-black">Ht: {height}</div>
                            <div className="text-black">Wt: {weight}</div>
                        </div>
                        <div className="text-black font-bold">Exp: {experience ? `${experience} yr(s)` : "Rookie"}</div>
                        {/* Career Touches */}
                        <div className="text-black font-bold">
                            Career Games Missed: {missedGamePercentage ? `${missedGamePercentage}%` : "N/A"}
                            <br />
                            Three Year Games Missed: {missedGamePercentageThree ? `${missedGamePercentageThree}%` : "N/A"}
                            <br />
                            Career Touches: {careerTouches ? `${careerTouches}` : "N/A"}
                            {/* Season Touches: {displayedStats ?
                                (!isNaN(displayedStats.pass_attempts) ? displayedStats.pass_attempts : 0) +
                                (!isNaN(displayedStats.rush_attempts) ? displayedStats.rush_attempts : 0) +
                                (!isNaN(displayedStats.receptions) ? displayedStats.receptions : 0) : 0} */}

                        </div>


                        {/* Pre-existing injuries */}
                        {preExistingInjuries && preExistingInjuries.injury_types.length > 0 && (
                            <div className="text-red-500 font-bold">
                                <div>
                                    Past Injuries: {preExistingInjuries.injury_types[0]}
                                    {preExistingInjuries.injury_types.length > 1 && (
                                        <span className="cursor-pointer" title={preExistingInjuries.injury_types.slice(1).join(', ')}>
                                            {' '}+ <span className="hover:underline">{preExistingInjuries.injury_types.length - 1} more</span>
                                        </span>
                                    )}
                                </div>
                            </div>
                        )}

                        {/* Current Injury Information (if applicable) */}
                        {/* Current Injury Information (if applicable) */}
                        {injury && (
                            <div className={`font-bold text-[13px] ${injury.status === 'QUESTIONABLE' ? 'text-yellow-500' : 'text-red-500'}`}>
                                <div>Current Injuries: {injury.description.split(' - ')[0]}</div> {/* Split at the hyphen and display the first part */}
                            </div>
                        )}

                        {/* Tabs to select year */}
                        <div className="flex justify-center py-0.5 gap-1">
                            <button
                                className={`w-1/2 rounded-md py-1 ${selectedYear === 2023 ? 'bg-green-600' : 'bg-gray-600'} text-white`}
                                onClick={(e) => { e.stopPropagation(); setSelectedYear(2023); }}
                            >
                                2023 Stats
                            </button>
                            <button
                                className={`w-1/2 rounded-md py-1 ${selectedYear === 2024 ? 'bg-green-600' : 'bg-gray-600'} text-white`}
                                onClick={(e) => { e.stopPropagation(); setSelectedYear(2024); }}
                            >
                                2024 Stats
                            </button>
                        </div>

                        {/* Conditionally render stats based on position */}
                        <div className="flex flex-col px-2 py-1">
                            {position === 'QB' ? renderQuarterbackStats() : renderNonQuarterbackStats()}
                        </div>
                    </>
                </div>


            </div>
        </div >
    );
};

export default PlayerCard;
