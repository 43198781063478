import React, { useState, useEffect } from 'react';
import * as tf from '@tensorflow/tfjs';
import GetInsights from './GetInsights';
import { modelConfig } from '../models/modelConfig';
import weightsFile from '../models/injsur.weights.bin';

const Frailty = () => {
    const [model, setModel] = useState(null);
    const [outputMessage, setOutputMessage] = useState('');

    useEffect(() => {
        const loadModel = async () => {
            try {
                setOutputMessage('Loading model...');

                // Fetch the binary data from the imported file
                const response = await fetch(weightsFile);
                const weightsArrayBuffer = await response.arrayBuffer();

                // Load the model using the config and weights
                const loadedModel = await tf.loadLayersModel(tf.io.fromMemory(
                    modelConfig.modelTopology,
                    [{ data: weightsArrayBuffer, name: 'weights' }]
                ));

                setModel(loadedModel);
                setOutputMessage('');
                console.log('Model:', loadedModel);
                await testPrediction();
            } catch (error) {
                setOutputMessage(`Error loading model: ${error.message}`);
            }
        };

        loadModel();
    }, []);


    const testPrediction = async () => {
        setOutputMessage('Testing prediction...');
        try {
            setOutputMessage('');
        } catch (error) {
            setOutputMessage(`Error testing prediction: ${error.message}`);
        }
    };

    const predictFrailty = async (playerDetails, playerStats) => {
        if (!model) {
            throw new Error('Model not loaded');
        }

        console.log("playerStats:", playerStats);  // Add this line


        const data = tf.tensor2d([Object.values(playerStats)], [1, 12]);
        const predictions = model.predict(data);
        const frailtyScoreTensor = await predictions.data();
        const frailtyScore = frailtyScoreTensor[0] * 10;
        return frailtyScore.toFixed(1);
    };

    const calculateAdjustedFrailtyScore = (frailtyScore, position) => {
        console.log("calculateAdjustedFrailtyScore called",frailtyScore)
        let adjustedFrailtyScore = parseFloat(frailtyScore);

        const minOriginal = 0.0;
        const maxOriginal = 6.0;
        const minTarget = 1.0;
        const maxTarget = 10.0;

        const mappedFrailtyScore = minTarget + ((adjustedFrailtyScore - minOriginal) * (maxTarget - minTarget) / (maxOriginal - minOriginal));
        console.log("mappedFrailtyScore", mappedFrailtyScore)
        return mappedFrailtyScore;
    };




    const impactValues = {
        QB: {
            concussions: 1.3, aclTears: 1.5, ankleSprains: 1.4, shoulderInjuries: 1.6,
            hamstringStrains: 1.2, groinStrains: 1.5, ribsChestInjuries: 1.4, backInjuries: 1.3,
            mclPclTears: 1.2, handFingerInjuries: 1.5, passingAttempts: 1.7, completions: 1.3,
            completionPct: 1.3, yards: 1.5, yardsPerPassAvg: 1.6, yardsPerGame: 1.2, touchdowns: 1.9,
            interceptions: 1.6, sacks: 1.5, sackedYardsLost: 1.3, quarterbackRating: 1.5,
            rushingAttempts: 1.2, rushingYards: 1.5, yardsPerRushAvg: 1.4, longestRush: 1.3,
            over20Yards: 1.5, rushingTouchdowns: 1.6, rushingYardsPerGame: 1.5, fumbles: 1.3,
            fumblesLost: 1.2, rushingFirstDowns: 1.4, temperature: 1.2, humidity: 1.5, windSpeed: 1.6,
            turfType: 1.4, altitude: 1.2, precipitation: 1.5, lighting: 1.3
        },
        RB: {
            concussions: 1.4, aclTears: 1.6, ankleSprains: 1.5, shoulderInjuries: 1.3,
            hamstringStrains: 1.6, groinStrains: 1.4, ribsChestInjuries: 1.5, backInjuries: 1.3,
            mclPclTears: 1.4, handFingerInjuries: 1.3, passingAttempts: 1.2, completions: 1.2,
            completionPct: 1.2, yards: 1.3, yardsPerPassAvg: 1.2, yardsPerGame: 1.4, touchdowns: 1.6,
            interceptions: 1.4, sacks: 1.2, sackedYardsLost: 1.2, quarterbackRating: 1.3,
            rushingAttempts: 1.9, rushingYards: 1.7, yardsPerRushAvg: 1.8, longestRush: 1.7,
            over20Yards: 1.5, rushingTouchdowns: 1.7, rushingYardsPerGame: 1.9, fumbles: 1.5,
            fumblesLost: 1.5, rushingFirstDowns: 1.7, temperature: 1.5, humidity: 1.2, windSpeed: 1.3,
            turfType: 1.5, altitude: 1.7, precipitation: 1.3, lighting: 1.5
        },
        WR: {
            concussions: 1.5, aclTears: 1.7, ankleSprains: 1.4, shoulderInjuries: 1.3,
            hamstringStrains: 1.6, groinStrains: 1.4, ribsChestInjuries: 1.7, backInjuries: 1.4,
            mclPclTears: 1.6, handFingerInjuries: 1.4, passingAttempts: 1.4, completions: 1.6,
            completionPct: 1.5, yards: 1.7, yardsPerPassAvg: 1.7, yardsPerGame: 1.6, touchdowns: 1.7,
            interceptions: 1.5, sacks: 1.4, sackedYardsLost: 1.4, quarterbackRating: 1.5,
            rushingAttempts: 1.3, rushingYards: 1.4, yardsPerRushAvg: 1.6, longestRush: 1.7,
            over20Yards: 1.7, rushingTouchdowns: 1.7, rushingYardsPerGame: 1.7, fumbles: 1.4,
            fumblesLost: 1.2, rushingFirstDowns: 1.4, temperature: 1.4, humidity: 1.7, windSpeed: 1.8,
            turfType: 1.7, altitude: 1.4, precipitation: 1.7, lighting: 1.4
        },
        TE: {
            concussions: 1.3, aclTears: 1.5, ankleSprains: 1.6, shoulderInjuries: 1.5,
            hamstringStrains: 1.3, groinStrains: 1.7, ribsChestInjuries: 1.5, backInjuries: 1.5,
            mclPclTears: 1.3, handFingerInjuries: 1.6, passingAttempts: 1.3, completions: 1.4,
            completionPct: 1.4, yards: 1.4, yardsPerPassAvg: 1.4, yardsPerGame: 1.6, touchdowns: 1.7,
            interceptions: 1.3, sacks: 1.2, sackedYardsLost: 1.4, quarterbackRating: 1.6,
            rushingAttempts: 1.5, rushingYards: 1.6, yardsPerRushAvg: 1.7, longestRush: 1.5,
            over20Yards: 1.5, rushingTouchdowns: 1.8, rushingYardsPerGame: 1.7, fumbles: 1.5,
            fumblesLost: 1.2, rushingFirstDowns: 1.7, temperature: 1.5, humidity: 1.7, windSpeed: 1.5,
            turfType: 1.3, altitude: 1.7, precipitation: 1.5, lighting: 1.5
        },
        OL: {
            concussions: 1.5, aclTears: 1.7, ankleSprains: 1.6, shoulderInjuries: 1.4,
            hamstringStrains: 1.7, groinStrains: 1.3, ribsChestInjuries: 1.5, backInjuries: 1.5,
            mclPclTears: 1.7, handFingerInjuries: 1.5, passingAttempts: 1.2, completions: 1.2,
            completionPct: 1.4, yards: 1.4, yardsPerPassAvg: 1.4, yardsPerGame: 1.3, touchdowns: 1.3,
            interceptions: 1.3, sacks: 1.5, sackedYardsLost: 1.3, quarterbackRating: 1.4,
            rushingAttempts: 1.3, rushingYards: 1.4, yardsPerRushAvg: 1.3, longestRush: 1.4,
            over20Yards: 1.4, rushingTouchdowns: 1.4, rushingYardsPerGame: 1.4, fumbles: 1.3,
            fumblesLost: 1.4, rushingFirstDowns: 1.3, temperature: 1.7, humidity: 1.4, windSpeed: 1.7,
            turfType: 1.7, altitude: 1.4, precipitation: 1.7, lighting: 1.3
        }
    };


    return (
        <div>
            <GetInsights
                model={model}
                predictFrailty={predictFrailty}
                calculateAdjustedFrailtyScore={calculateAdjustedFrailtyScore}
            // Pass any other necessary functions or state
            />
        </div>
    );
};

export default Frailty;
