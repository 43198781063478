import React from 'react';
import Header2 from './Header2';
import Footer from './Footer';

const PrivacyPolicy = () => {
    return (
        <div>
            <Header2/>
            <div className="privacy-policy-container px-4 sm:px-10 md:px-20 lg:px-40 xl:px-60 py-16 text-green-950">
                <h1 className="text-4xl font-bold mb-8">Injsur Privacy Policy</h1>
                <p className="mb-8 font-bold">Last updated: August 16th, 2024</p>
                <p className="mb-8">
                    This Privacy Policy explains how we collect, use, and share the personal information that we
                    gather on the subdomains and websites on
                    <a href="https://injsur.ai" className="text-green-950 underline"> https://injsur.ai</a> (the “Sites”), through our mobile
                    applications (the “Apps”), and through other interactions with you, including through customer
                    service communications (collectively, “Services”). A reference to “Company,” “we,” “us,” or
                    “our” is a reference to Injsur and its relevant affiliates in the collection, use, sharing, or other
                    processing of personal information.
                </p>
                <p className="mb-8">
                    Our Services may contain links to and from the websites of third parties. If you follow a link to
                    any of these websites, please note that these websites have their own privacy policies and that we
                    do not accept any responsibility or liability for these policies. Please check these policies before
                    you submit any data to these websites.
                </p>
                <p className="mb-8">Please click the following links to learn more about our Privacy Policy:</p>
                <ul className="list-disc ml-8 mb-8">
                    <li><a href="#information-we-collect" className="text-green-950 underline">Information We Collect</a></li>
                    <li><a href="#how-we-use-your-information" className="text-green-950 underline">How We Use Your Information</a></li>
                    <li><a href="#how-we-share-your-information" className="text-green-950 underline">How We Share Your Information</a></li>
                    <li><a href="#do-not-track-signals" className="text-green-950 underline">How We Respond to ‘Do Not Track’ Signals</a></li>
                    <li><a href="#cookies" className="text-green-950 underline">Cookies</a></li>
                    <li><a href="#privacy-rights" className="text-green-950 underline">Privacy Rights & Additional Disclosures for Certain U.S. Residents</a></li>
                    <li><a href="#childrens-privacy" className="text-green-950 underline">Children’s Privacy</a></li>
                    <li><a href="#change-of-control" className="text-green-950 underline">Change of Control</a></li>
                    <li><a href="#data-security" className="text-green-950 underline">Data Security & Retention</a></li>
                    <li><a href="#international-transfers" className="text-green-950 underline">International Transfers</a></li>
                    <li><a href="#policy-updates" className="text-green-950 underline">Policy Updates</a></li>
                    <li><a href="#contact-information" className="text-green-950 underline">Contact Information</a></li>
                </ul>

                <h2 id="information-we-collect" className="text-3xl font-bold mb-4">1. Information We Collect</h2>
                <p className="mb-8">
                    <strong>Information You Give Us:</strong> You may give us information by signing up for an online account,
                    signing up for emails, entering information through our online forms or surveys, inputting
                    information while using our Services, contacting us through our chat service, or contacting us by
                    email for information or customer service.
                </p>
                <p className="mb-8">The categories of information include:</p>
                <ul className="list-disc ml-8 mb-8">
                    <li>Identifiers, such as real name, Internet Protocol address, email address, or other similar
                        identifiers.</li>
                    <li>Categories of personal information described in Section 1798.80(e) of the California
                        Civil Code, such as name.</li>
                    <li>Commercial information, such as products or services purchased, obtained, or considered,
                        or other purchasing or consuming histories or tendencies.</li>
                    <li>Internet or other electronic network activity information, such as browsing history, search
                        history, and information regarding your interaction with an Internet website, application,
                        or advertisement.</li>
                    <li>Geolocation data, including approximate location when viewing emails.</li>
                    <li>Visual information, including photos and avatars.</li>
                    <li>Inferences drawn from the above categories of information.</li>
                </ul>
                <p className="mb-8">The categories of sensitive information include:</p>
                <ul className="list-disc ml-8 mb-8">
                    <li>Account log-in, in combination with any required security or access code, password, or
                        credentials allowing access to the account.</li>
                </ul>
                <p className="mb-8">
                    <strong>Information We Automatically Collect:</strong>
                </p>
                <ul className="list-disc ml-8 mb-8">
                    <li>
                        <strong>Sites:</strong> Like many website operators, we collect information that your browser sends
                        whenever you visit our Sites. This includes log data, such as your computer’s IP address,
                        browser type, browser version, the pages of our Sites that you visit, the time and date of
                        your visit, the time spent on those pages and other statistics, and whether you reached our
                        page via a social media or email campaign. This information may be collected via several
                        technologies, including cookies, web beacons, clear GIFs, canvas fingerprinting and other
                        means, such as Google Remarketing and Facebook Pixel. You can control cookies in your
                        browser to enable or disable them.
                    </li>
                    <li>
                        <strong>Apps:</strong> Some of our applications will collect IP address, log-in credentials, connectivity
                        details and locations, operating systems and versions, software, preferred languages,
                        unique device identifiers, advertising identifiers, serial numbers, and mobile network
                        data.
                    </li>
                </ul>
                <p className="mb-8">
                    <strong>Information We Collect From Third Parties:</strong> If you access our Sites through third parties (e.g.,
                    Facebook or Google), or if you share content from our Sites to a third-party social media service,
                    the third-party service will send us certain information about you if the third-party service and
                    your account settings allow such sharing. The information we receive will depend on the policies
                    and your account settings with the third-party service.
                </p>
                <p className="mb-8">
                    <strong>Publicly Available Information About Players:</strong> We access publicly available information about
                    NFL player statistics and player injuries, in order to develop predictive analytics tailored for
                    NFL player injuries.
                </p>

                <h2 id="how-we-use-your-information" className="text-3xl font-bold mb-4">2. How We Use Your Information</h2>
                <p className="mb-8">
                    We use personal information for the following purposes:
                </p>
                <ul className="list-disc ml-8 mb-8">
                    <li>To provide you with our Services, which includes:
                        <ul className="list-disc ml-8 mb-8">
                            <li>Maintaining and servicing accounts</li>
                            <li>Providing customer service</li>
                            <li>Processing and fulfilling orders and transactions</li>
                            <li>Verifying customer information</li>
                            <li>Processing payments</li>
                            <li>Providing advertising or marketing services to you</li>
                        </ul>
                    </li>
                    <li>From publicly available player data, to develop predictive analytics tailored for NFL
                        player injuries.
                    </li>
                    <li>To engage in analytic services, such as analyzing customer usage and improving services
                        offered.
                    </li>
                    <li>To conduct market research and project planning.
                    </li>
                    <li>To detect security incidents, protect against fraudulent or other criminal activity, debug
                        and repair errors, and maintain the overall quality and safety of our Services.
                    </li>
                    <li>To share your website visitor activity, through website cookies, pixels, and trackers, with
                        third-party marketing partners to market our Services on our Sites and other websites that
                        you may visit.
                    </li>
                    <li>To fulfill our legal and financial obligations.
                    </li>
                    <li>To provide you with employment opportunities.
                    </li>
                </ul>
                <p className="mb-8">
                    <strong>Marketing:</strong> We may use your personal information to contact you with newsletters, marketing or
                    promotional materials and other information that may be of interest to you. You may opt out of
                    receiving any, or all, of these communications from us by following the unsubscribe instructions
                    provided in any email we send, or you can contact us using the contact details provided in the
                    “Contact Information” section below. You will still continue to receive service-related messages
                    concerning products and services you have purchased (unless we have indicated otherwise).
                </p>

                <h2 id="how-we-share-your-information" className="text-3xl font-bold mb-4">3. How We Share Your Information</h2>
                <p className="mb-8">
                    <strong>To Users or the Public with Your Consent:</strong> We may share your information with other users or
                    the public with your consent.
                </p>
                <p className="mb-8">
                    <strong>Affiliates and Service Providers:</strong> We share your information with our third-party service
                    providers, affiliates, and any subcontractors as required to offer you our products and Services.
                    The service providers we use help us to:
                </p>
                <ul className="list-disc ml-8 mb-8">
                    <li>Run, operate, and maintain our Services through third-party platforms and software tools;</li>
                    <li>Offer and maintain our rewards program;</li>
                    <li>Perform crash analytics;</li>
                    <li>Run email messaging campaigns;</li>
                    <li>Perform marketing analytics;</li>
                    <li>Provide measurement services and targeted ads (you can opt out of these services at
                        websites such as <a href="http://www.aboutads.info/choices" className="text-green-950 underline">http://www.aboutads.info/choices</a> and
                        <a href="http://www.youronlinechoices.eu/" className="text-green-950 underline"> http://www.youronlinechoices.eu/</a>);
                    </li>
                    <li>Administer live events, competitions, sweepstakes, and promotions, including eligibility
                        and prize fulfilment;
                    </li>
                    <li>Provide payment attribution; and,</li>
                    <li>Provide technical and customer support.</li>
                </ul>
                <p className="mb-8">
                    Some external service providers may also collect information directly from you (for example, a
                    payment processor may request your billing and financial information) in accordance with their
                    own privacy policy. These external service providers do not share your financial information,
                    like credit card number, with us, but may share limited information with us related to your
                    purchase, like your zip code.
                </p>
                <p className="mb-8">
                    <strong>Aggregated Data:</strong> We may also aggregate or otherwise strip information of all personally
                    identifying characteristics and may share that aggregated, anonymized data with third parties or
                    publish it. We reserve the right to make use of any such aggregated data as we see fit.
                </p>
                <p className="mb-8">
                    <strong>Third Party Marketing and Advertising Organizations:</strong> With respect to website cookies,
                    pixels, and trackers, we share your information with third-party marketing and advertising
                    partners to market our Services on our Sites and other websites that you may visit.
                </p>
                <p className="mb-8">
                    <strong>Disclosures Required by Law:</strong> We may be required to disclose your data in response to lawful
                    requests by public authorities, including to meet law enforcement requirements. We may be
                    under a duty to disclose or share your personal information to comply with any legal obligation,
                    to enforce or apply our terms and conditions and other agreements, to protect our rights,
                    property, or safety, or to protect the rights, property, or safety of others. This includes exchanging
                    information with other companies and organizations for the purposes of fraud protection.
                </p>

                <h2 id="do-not-track-signals" className="text-3xl font-bold mb-4">4. How We Respond to ‘Do Not Track’ Signals</h2>
                <p className="mb-8">
                    Some browsers provide you with a way to signal that you do not want your browsing activity to
                    be tracked. The Services may not currently respond to all Do Not Track (“DNT”) or similar
                    signals, as we are awaiting consensus from the Internet policy and legal community on the
                    meaning of DNT and the best way to respond to these signals.
                </p>
                <p className="mb-8">
                    California and certain other U.S. state residents may opt out of tracking technologies by
                    broadcasting an Opt-Out Preference Signal, such as the Global Privacy Control (GPC) (on the
                    browsers and/or browser extensions that support such a signal). To download and use a browser
                    supporting the GPC browser signal, click here: <a href="https://globalprivacycontrol.org/orgs" className="text-green-950 underline">https://globalprivacycontrol.org/orgs</a>. If you
                    choose to use the GPC signal, you will need to turn it on for each supported browser or browser
                    extension you use. Your request to opt-out of sale/sharing will be linked to your browser
                    identifier only and not linked to any account information because the connection between your
                    browser and the account is not known to us.
                </p>

                <h2 id="cookies" className="text-3xl font-bold mb-4">5. Cookies, Pixels, and Website Trackers</h2>
                <p className="mb-8">
                    To make our Sites and Services work properly, we sometimes place small data files called
                    cookies on your device or similar pixels, scripts, or website trackers. A cookie is a small text file
                    that a website saves on your computer or mobile device when you visit the site. It enables the
                    website to remember your actions and preferences (such as login, shopping cart, or other
                    preferences) over a period of time, so you do not have to keep re-entering them whenever you
                    come back to the site or browse from one page to another.
                </p>
                <p className="mb-8">
                    <strong>Google/ Squarespace Analytics:</strong> We may use analytics cookies to improve the Sites. We do not
                    combine the information collected through the use of analytics cookies with personally
                    identifiable information.
                </p>
                <p className="mb-8">
                    You may wish to restrict the use of cookies. Please be aware that some of the features of our
                    Sites may not function correctly if you disable cookies. Most modern browsers allow you to
                    change your cookie settings. You can usually find these settings in the options or preferences
                    menu of your browser. To understand these settings, the following links for commonly used
                    browsers may be helpful:
                </p>
                <ul className="list-disc ml-8 mb-8">
                    <li><a href="https://support.google.com/chrome/answer/95647" className="text-green-950 underline">Cookie settings in Chrome</a></li>
                    <li><a href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer" className="text-green-950 underline">Cookie settings in Firefox</a></li>
                    <li><a href="https://support.microsoft.com/en-us/help/4027947/microsoft-edge-delete-cookies" className="text-green-950 underline">Cookie settings in Microsoft Edge</a></li>
                    <li><a href="https://support.apple.com/en-gb/guide/safari/sfri11471/mac" className="text-green-950 underline">Cookie settings in Safari web and iOS</a></li>
                </ul>
                <p className="mb-8">
                    <strong>Personalized Advertising:</strong> We may also use targeted advertising cookies, pixels, and other
                    trackers such as Google Ads, Meta Pixel, Bing Ads, TikTok Ads, and Pinterest Ads, among
                    others, to deliver tailored advertising on our Sites and other websites that you may visit. You can
                    learn more about how to control advertising cookies by visiting the Network Advertising
                    Initiative’s Consumer Opt-Out link, the DAA’s Consumer Opt-Out link for browsers, or the
                    DAA’s opt-out link for mobile devices. Please note that electing to opt-out will not stop
                    advertising from appearing in your browser or applications and may make the ads you see less
                    relevant to your interests.
                </p>

                <h2 id="privacy-rights" className="text-3xl font-bold mb-4">6. Privacy Rights & Additional Disclosures for Certain U.S. Residents</h2>
                <p className="mb-8">
                    Several jurisdictions grant state residents certain rights and disclosures. We provide the following
                    information to further help you understand your potential privacy rights. If you would like to
                    exercise any rights or inquire as to whether such rights are available to you, please email us at
                    support@injsur.ai.
                </p>
                <p className="mb-8">
                    <strong>Request for Information or Deletion:</strong> Residents of certain U.S. states have the right to know
                    whether we are processing your personal information, and in some instances, you have the right
                    to request that we disclose to you the categories listed below for the preceding 12 months. We
                    have the right to request verification of your identity for all requests for information. In
                    responding to this right, we shall provide to you:
                </p>
                <ul className="list-disc ml-8 mb-8">
                    <li>The categories of personal information we collect about you.</li>
                    <li>The categories of sources from which your personal information is collected.</li>
                    <li>The business or commercial purpose(s) for collecting, selling, sharing, or disclosing your
                        personal information, and the categories of personal information disclosed for such
                        purpose(s).</li>
                    <li>The categories of third parties with whom we share your personal information.</li>
                    <li>The categories of personal information we have sold, if any, about you and the categories
                        of third parties to whom your personal information was sold, by category or categories of
                        personal information for each third party to whom the personal information was sold.</li>
                    <li>The specific pieces of personal information we have collected about you.</li>
                </ul>
                <p className="mb-8">
                    In addition, you may have the right to request we delete your personal information.
                </p>
                <p className="mb-8">
                    <strong>Request for Correction:</strong> Residents of certain U.S. states have the right to request the correction
                    or rectification of inaccurate information in the resident’s personal information.
                </p>
                <p className="mb-8">
                    <strong>Request for Portable Data:</strong> Residents of certain U.S. states have the right to receive, in certain
                    circumstances, a portable format of their personal information that allows the data to be
                    transmitted to another entity.
                </p>
                <p className="mb-8">
                    <strong>Opting Out of Cookies and Sale/Sharing Using Online Tracking Technologies:</strong> Our use of
                    online tracking technologies may be considered a “sale” / “sharing” under applicable law.
                    Residents of certain U.S. states may have the right to opt out of being tracked by these third
                    parties by clicking the <a href="#" className="text-green-950 underline">[Your Privacy Choices / Do Not Sell or Share My Personal Information]</a>
                    link at the bottom of our Site and selecting your preferences.
                </p>
                <p className="mb-8">
                    Residents of certain U.S. states may opt out by broadcasting an opt-out preference signal, such as
                    the Global Privacy Control (GPC) (on the browsers and/or browser extensions that support such
                    a signal). To download and use the browser supporting the GPC browser signal, click here:
                    <a href="https://globalprivacycontrol.org/orgs" className="text-green-950 underline"> https://globalprivacycontrol.org/orgs</a>. If you choose to use the GPC signal, you will need to turn
                    it on for each supported browser or browser extension you use.
                </p>
                <p className="mb-8">
                    <strong>Do Not Sell or Share My Personal Information:</strong> Residents of certain U.S. states have the right
                    to opt out of the sale or sharing of the consumer’s personal information. We do not sell your
                    personal information, but we may use your personal information to provide personalized or
                    targeted advertising. In such circumstances, you may have the right to direct us not to share your
                    personal information for such purposes.
                </p>
                <p className="mb-8">
                    If you wish to opt out of this sharing of your personal information for personalized or targeted
                    advertising, please visit the <a href="#" className="text-green-950 underline">[Your Privacy Choices / Do Not Sell or Share My Personal
                        Information]</a> link in the footer.
                </p>
                <p className="mb-8">
                    <strong>Limiting the Use of Sensitive Personal Information:</strong> Residents of California have the right to
                    direct us to use or disclose sensitive personal information only for providing goods or services,
                    or as otherwise minimally permitted under applicable law. However, we do not use or disclose
                    sensitive personal information for any purpose other than providing our goods and services to
                    you, or as otherwise minimally permitted under applicable law.
                </p>
                <p className="mb-8">
                    <strong>Third Party Marketing:</strong> If you are one of our California customers, California Civil Code
                    Section 1798.83 permits you to request information regarding the disclosure of your personal
                    information to third parties for the third parties’ direct marketing purposes. We do not disclose
                    your personal information to third parties for the third parties’ direct marketing purposes.
                </p>
                <p className="mb-8">
                    <strong>Automated Processing & Profiling:</strong> Residents of certain U.S. states have the right to opt out of
                    the processing of personal information for purposes of profiling in furtherance of decisions that
                    produce legal or similarly significant effects concerning a consumer.
                </p>
                <p className="mb-8">
                    <strong>Right to Equal Service & Price:</strong> You have the right to receive equal service and price, even if
                    you exercise a privacy right.
                </p>
                <p className="mb-8">
                    <strong>Verification Process for Exercising Rights:</strong> To protect our consumers’ privacy, we verify
                    privacy rights requests to ensure that only you (or your authorized agent) can exercise rights
                    pertaining to your personal information. As part of our verification process, we may request you
                    to submit additional information.
                </p>
                <p className="mb-8">
                    If you are an authorized agent wishing to exercise rights on behalf of a state resident, please
                    contact us using the information at the bottom of this Privacy Policy and provide us with a copy
                    of the consumer’s written authorization designating you as their agent. We may need to verify
                    your identity and place of residence before completing your rights request.
                </p>
                <p className="mb-8">
                    <strong>Appeal Process:</strong> If we do not decide to act in response to your request to exercise a right, we
                    will provide a timely response detailing the reasons for not taking the action and, depending on
                    your jurisdiction, any rights that you may have to appeal our decision.
                </p>

                <h2 id="childrens-privacy" className="text-3xl font-bold mb-4">7. Children’s Privacy</h2>
                <p className="mb-8">
                    The Sites are not directed at individuals under the age of 16. We do not knowingly collect
                    personal information from children under 16. If you become aware that a child has provided us
                    with personal information, please contact us using the contact details provided at the end of this
                    Privacy Policy. If we become aware that a child under 16 has provided us with personal
                    information, we will take steps to delete such information.
                </p>

                <h2 id="change-of-control" className="text-3xl font-bold mb-4">8. Change of Control</h2>
                <p className="mb-8">
                    Personal information may be transferred to a third party because of a sale, acquisition, merger,
                    reorganization, or other change in control. If we sell, merge, or transfer any part of the business,
                    part of the sale may include your personal information.
                </p>

                <h2 id="data-security" className="text-3xl font-bold mb-4">9. Data Security & Retention</h2>
                <p className="mb-8">
                    We implement a variety of security measures to maintain the safety of your personal information
                    when you enter, submit, or access your personal information. However, no website, application,
                    or transmission can guarantee security. Thus, while we have established and maintain what we
                    believe to be appropriate technical and organizational measures to protect the confidentiality,
                    security, and integrity of personal information obtained through the Services, we cannot ensure
                    or warrant the security of any information you transmit to us.
                </p>
                <p className="mb-8">
                    We retain information from or about you for so long as necessary to fulfill the purposes outlined
                    in this Privacy Policy. When the information is no longer necessary for these purposes, we delete
                    it or keep it in a form that does not identify you, unless we are required by law to keep this
                    information for a longer period. When determining the retention period, we take into account
                    various criteria, such as the type of products and services requested by or provided to you, the
                    nature and length of our relationship with you, possible re-enrollment with our products or
                    services, the impact on the services we provide to you if we delete some information from or
                    about you, mandatory retention periods provided by law and the statute of limitations.
                </p>

                <h2 id="international-transfers" className="text-3xl font-bold mb-4">10. International Transfers</h2>
                <p className="mb-8">
                    Any data that you provide to us may be accessed, shared, or processed by our offices, located in
                    the United States, and service providers located in the United States, if such data transfer is
                    necessary for the specific purpose for which you submitted your data (such as the provision of
                    goods or services under a written contract). This may entail the transfer of your personal
                    information across international borders. The data protection standards may differ and be lower
                    than the standards enforced in your jurisdiction.
                </p>

                <h2 id="policy-updates" className="text-3xl font-bold mb-4">11. Policy Updates</h2>
                <p className="mb-8">
                    This policy may be periodically updated. We encourage you to periodically review this Privacy
                    Policy to ensure you are familiar with the most current version. Please review carefully before
                    submitting personal information to our Services. The date the Privacy Policy was last updated is
                    indicated at the top of this page.
                </p>

                <h2 id="contact-information" className="text-3xl font-bold mb-4">12. Contact Information</h2>
                <p className="mb-8">
                    If you wish to contact us or have any questions about or complaints in relation to this Privacy
                    Policy, please visit our Contact Page or write to us at:
                    support@injsur.ai
                </p>
            </div>
            <Footer/>
        </div>
    );
};

export default PrivacyPolicy;
