import React, { useState, useEffect } from 'react';
import {
    fetchPlayerDetails,
    fetchPlayerStatistics,
    fetchQuarterbackDetails,
    fetchRunningBackDetails,
    fetchWideReceiverDetails,
    fetchTightEndDetails
} from './utilityFunctions';
import PlayerCard from './PlayerCard';
import offensivePlayers from './combined_players.json'; // Import combined_players.json

const Game = ({ teamId, teamName, predictFrailty, calculateAdjustedFrailtyScore }) => {
    const [players, setPlayers] = useState([]);
    const [fetchedPlayers, setFetchedPlayers] = useState([]); // Store fetched players separately
    const [outputMessage, setOutputMessage] = useState('');
    const [loading, setLoading] = useState(true);

    // Fetch players for the selected team (home or away)
    useEffect(() => {
        const fetchTeamPlayers = async () => {
            setOutputMessage('Fetching players...');
            setLoading(true);

            try {
                // Filter players by teamName across positions QB, RB, WR, TE
                const teamPlayers = Object.values(offensivePlayers).flatMap(position =>
                    position.filter(player => player.team === teamName)
                );

                if (teamPlayers.length === 0) {
                    setOutputMessage('No players found for this team.');
                    setLoading(false);
                    return;
                }

                // Fetch player details and stats for all offensive players
                const playerDetailsWithStats = await Promise.all(
                    teamPlayers.map(async (player) => {
                        let playerDetails;

                        // Fetch player details based on position
                        if (player.position === 'QB') {
                            playerDetails = await fetchQuarterbackDetails({ player });
                        } else if (player.position === 'RB') {
                            playerDetails = await fetchRunningBackDetails({ player });
                        } else if (player.position === 'WR') {
                            playerDetails = await fetchWideReceiverDetails({ player });
                        } else if (player.position === 'TE') {
                            playerDetails = await fetchTightEndDetails({ player });
                        }

                        if (playerDetails) {
                            const statsResult = await fetchPlayerStatistics(player);

                            // Calculate frailty score regardless of injury status
                            const { displayStats2023, displayStats2024 } = statsResult || {};
                            let frailtyScore = "No Stats";

                            if (statsResult?.normalizedStats) {
                                frailtyScore = await predictFrailty(playerDetails, statsResult.normalizedStats);
                                frailtyScore = !isNaN(parseFloat(frailtyScore)) ? parseFloat(frailtyScore) : "No Stats";
                            }

                            const adjustedFrailtyScore = frailtyScore === "No Stats" ? "No Stats" : calculateAdjustedFrailtyScore(frailtyScore, playerDetails);

                            return {
                                ...player,
                                details: playerDetails,
                                stats2023: displayStats2023,
                                stats2024: displayStats2024,
                                frailtyScore: adjustedFrailtyScore,
                                injury: statsResult?.injury, // Pass along injury information
                                injuryStatus: statsResult?.injuryStatus,
                                age: playerDetails.age,
                                height: playerDetails.height,
                                weight: playerDetails.weight,
                                experience: playerDetails.experience,
                                preExistingInjuries: playerDetails.preExistingInjuries,
                                missedGamePercentage: playerDetails.missedGamePercentage,
                                missedGamePercentageThree: playerDetails.missedGamePercentageThree,
                                careerTouches: playerDetails.careerTouches
                                
                            };
                        }

                        return null;
                    })
                );

                // Filter out players with no stats (frailty score of "No Stats")
                const filteredPlayers = playerDetailsWithStats.filter(player => player !== null && player.frailtyScore !== "No Stats");

                // Sort players by frailty score and handle injury status
                const sortedPlayers = filteredPlayers.sort((a, b) => {
                    if (a.injury?.status !== 'Questionable' && a.injury) return 1;
                    if (b.injury?.status !== 'Questionable' && b.injury) return -1;

                    if (typeof a.frailtyScore === 'number' && typeof b.frailtyScore === 'number') {
                        return b.frailtyScore - a.frailtyScore;
                    } else if (typeof a.frailtyScore === 'number') {
                        return -1;
                    } else if (typeof b.frailtyScore === 'number') {
                        return 1;
                    }

                    return 0;
                });

                if (sortedPlayers.length > 0) {
                    setFetchedPlayers(sortedPlayers); // Store fetched players separately
                    setPlayers(sortedPlayers); // Set the initial players state
                    setOutputMessage('');
                } else {
                    setOutputMessage('No offensive players found with valid data.');
                }
                setLoading(false); // Data fetched, stop loading
            } catch (error) {
                setOutputMessage(`Error fetching players: ${error.message}`);
                setLoading(false);
            }
        };

        fetchTeamPlayers();
    }, [teamName, predictFrailty, calculateAdjustedFrailtyScore]);

    return (
        <div>
            <div className="flex items-center justify-start  p-5">
            </div>

            {/* {loading && (
                <div className=" top-1/3 left-0 w-full h-full flex flex-col items-center justify-start z-50 bg-white bg-opacity-70">
                    <div className="w-16 h-16 border-t-4 border-b-4 border-green-500 rounded-full animate-spin mt-8"></div>
                    <p className="mt-4 text-lg text-black">Loading Players...</p>
                </div>
            )} */}

            {!loading && outputMessage && <p>{outputMessage}</p>}

            {!loading && players.length > 0 ? (
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 justify-center place-items-center">
                    {players.map((player, index) => (
                        <PlayerCard
                            key={index}
                            playerId={player.details?.id}
                            playerImage={player.details?.image}
                            teamName={teamName}
                            frailtyScore={typeof player.frailtyScore === 'number' && !isNaN(player.frailtyScore) ? player.frailtyScore.toFixed(2) : player.frailtyScore}
                            playerName={player.details?.name}
                            position={player.details?.position}
                            playerStats2023={player.stats2023}
                            playerStats2024={player.stats2024}
                            injury={player.injury}
                            injuryStatus={player.injuryStatus}
                            age={player.age}
                            height={player.details?.height}
                            weight={player.details?.weight}
                            experience={player.details?.experience}
                            preExistingInjuries={player.details?.preExistingInjuries}
                            missedGamePercentage={player.details?.missedGamePercentage} // Pass missed game percentage
                            missedGamePercentageThree={player.details?.missedGamePercentageThree}
                            careerTouches={player.details?.careerTouches}
                        />
                    ))}
                </div>
            ) : null}
        </div>
    );
};

export default Game;
