import React from 'react';
import { Link } from 'react-router-dom';
import Header2 from './Header2';

const FAQ = () => {
    return (
        <>
            <Header2 />
            <div className="faq-container px-10 py-10 sm:px-16 md:px-20 lg:px-24 xl:px-32 transition duration-200 animate-fade-up">
                <h2 className="text-2xl font-bold mb-4 ">Frequently Asked Questions</h2>

                <div className="mb-4">
                    <h3 className="text-lg font-semibold">What is a frailty score?</h3>
                    <p className="mb-4">
                        Injsur.ai is a cutting-edge application that uses advanced machine learning to assess the frailty of NFL players. Our proprietary model, trained on extensive player data dating back to 2005, provides our users with crucial insights into the statistical probability of player health and performance.
                    </p>
                    <p className="mb-4">
                        The Injsur model is built on a comprehensive dataset including player demographics (age, height, weight, position), performance statistics (career touches, games played), current physical performance metrics, detailed injury history, workload data (snap counts, practice participation), and more.
                    </p>
                    <p className="mb-4">
                        Our proprietary machine learning model is developed using state-of-the-art techniques to produce an accurate Frailty Score showing the level of risk of injury for each player.
                    </p>
                    <p className="mb-4">
                        The Injsur algorithm processes player data and outputs a Frailty Score on a scale of 0-10.
                    </p>
                </div>

                <div className="mb-4">
                    <h3 className="text-lg font-semibold">What data is being used to determine players' injury probability?</h3>
                    <p className="mb-4">
                        Hundreds of data points, variables, historical, and current data contribute to the injury probability assessments. This includes player-specific data, game conditions, and team dynamics to provide a comprehensive analysis.
                    </p>

                </div>

                <div className="mb-4">
                    <h3 className="text-lg font-semibold">What happens when a player is already injured or questionable for that week's game?</h3>
                    <p className="mb-4">
                        Proper weighting is applied through the engine to raise or lower Injsur's frailty scoring.
                    </p>
                    <p className="mb-4">
                        This ensures that players' current health status and injury severity are accurately reflected in their overall injury probability.
                    </p>
                </div>

                <div className="mb-4">
                    <h3 className="text-lg font-semibold">Are these projections for each week, or for the entire season?</h3>
                    <p className="mb-4">
                        They are probabilities on both a weekly and season-long basis.
                    </p>
                    <p className="mb-4">
                        This allows users to understand both short-term risks for upcoming games and long-term risks over the course of the season.
                    </p>
                </div>

                <div className="mb-4">
                    <h3 className="text-lg font-semibold">How is Injsur different from other injury or information sites?</h3>
                    <p className="mb-4">
                        While many other sites/apps provide information regarding injuries that have already happened, Injsur uses its patent-pending AI engine to predict the probability of injury *before* it happens.
                    </p>
                    <p className="mb-4">
                        This forward-looking approach allows teams, coaches, and fans to make more informed decisions about player availability and performance risks.
                    </p>
                </div>

                <div className="mb-4">
                    <h3 className="text-lg font-semibold">How often is your data updated?</h3>
                    <p className="mb-4">
                        Data is updated daily to ensure the most accurate and current insights are available for users.
                    </p>
                    <p className="mb-4">
                        This frequent updating helps account for any changes in player conditions, performance, and other relevant factors.
                    </p>
                </div>

                <div className="mb-4">
                    <h3 className="text-lg font-semibold">How do I subscribe?</h3>
                    <p className="mb-4">
                        To subscribe, visit the sign up page <Link to="/sign-up" className="text-blue-500 underline">here</Link> and follow the provided instructions.
                    </p>

                </div>

                <div className="mb-4">
                    <h3 className="text-lg font-semibold">What payment methods are accepted?</h3>
                    <p className="mb-4">
                        We accept all major credit cards.
                    </p>
                </div>

                <div className="mb-4">
                    <h3 className="text-lg font-semibold">How do I reset my password?</h3>
                    <p className="mb-4">
                        To reset your password, visit the reset password page <Link to="/reset-password" className="text-blue-500 underline">here</Link> and follow the provided instructions.
                    </p>
                </div>

                <div className="mb-4">
                    <h3 className="text-lg font-semibold">How do I change my account information?</h3>
                    <p className="mb-4">
                        To change your account information, please login to your account and visit the account settings page where you will have several options to change or update your information.
                    </p>
                </div>

                <div className="mb-4">
                    <h3 className="text-lg font-semibold">How do I contact customer support?</h3>
                    <p className="mb-4">
                        To contact customer support, please reach out to support@injsur.ai
                    </p>
                </div>

                <div className="mb-4">
                    <h3 className="text-lg font-semibold">What new features will be available or added?</h3>
                    <p className="mb-4">
                        Information about upcoming features will be provided here.
                    </p>
                </div>
                <div className="flex justify-center items-center">
                    <Link to="/login">
                        <button
                            className="flex font-bold mt-4 text-black text-lg sm:text-xl md:text-2xl px-6 py-4 mb-4 mt-4 rounded-full justify-center transition duration-200"
                            style={{
                                fontFamily: 'Montserrat, sans-serif',
                                backgroundColor: '#d8bf82',
                                color: 'black',
                                borderColor: 'white',
                            }}
                            onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#a78c5d')}
                            onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = '#d8bf82')}
                        >
                            SUBSCRIBE TO INJSUR.AI
                        </button>
                    </Link>
                </div>
            </div>
        </>
    );
};

export default FAQ;
