// aws-exports.js
const awsmobile = {
    aws_project_region: 'us-west-1', // e.g., 'us-east-1'
    aws_cognito_identity_pool_id: 'us-west-1:6ceddf94-4444-4729-a8f7-a21820d9209d',
    aws_cognito_region: 'us-west-1', // e.g., 'us-east-1'
    aws_user_pools_id: 'us-west-1_nKKEndQj9',
    aws_user_pools_web_client_id: '3enp9apsnc9iiq49q7ae6q008v',
};

export default awsmobile;
