import React, { createContext, useContext, useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { CognitoUser, CognitoUserPool } from "amazon-cognito-identity-js";

export const AuthContext = createContext();

export const UseAuth = () => {
    const context = useContext(AuthContext);
    return context;
};



export const AuthProvider = ({ children }) => {

    const [token, setToken] = useState(localStorage.getItem('userToken'));
    const [loading, setLoading] = useState(true);

    const isTokenExpired = (token) => {
        try {
            const payload = JSON.parse(atob(token.split('.')[1]));
            return payload.exp < Date.now() / 1000;
        } catch (e) {
            console.error('Error checking token expiration:', e);
            return false;
        }
    };
    //   console.log("isTokenExpired",isTokenExpired(token))

    const refreshAccessToken = async () => {
        try {
            const currentUser = await Auth.currentAuthenticatedUser();
            const userPool = new CognitoUserPool({
                UserPoolId: Auth.configure().userPoolId,
                ClientId: Auth.configure().userPoolWebClientId,
            });

            const cognitoUser = new CognitoUser({
                Username: currentUser.username,
                Pool: userPool,
            });

            const currentSession = await Auth.currentSession();
            const refreshToken = currentSession.getRefreshToken();

            cognitoUser.refreshSession(refreshToken, (err, session) => {
                if (err) {
                    console.error("Error refreshing token:", err);
                    return;
                }
                const newAccessToken = session.getAccessToken().getJwtToken();
                const newIdToken = session.getIdToken().getJwtToken();
                // Assuming you have a method in your context to update the token
                setToken(newAccessToken);
                // console.log("Token successfully refreshed. New Access Token:", newAccessToken);
                // console.log("New ID Token:", newIdToken);
            });
        } catch (error) {
            console.error("Error retrieving current user or session:", error);
        }
    };



    useEffect(() => {

        // Save token to localStorage when it changes
        if (token) {
            localStorage.setItem('userToken', token);
        } else {
            localStorage.removeItem('userToken');
        }
    }, [token]);

    // Set loading to false after component mounts
    useEffect(() => {
        setLoading(false);
    }, []);

    const contextValue = {
        token,
        setToken,
        isTokenExpired,
        refreshAccessToken,
    };


    if (loading) {
        return null;
    }

    return (
        <AuthContext.Provider value={contextValue}>
            {children}
        </AuthContext.Provider>
    );
};

