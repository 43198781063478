import { CognitoUserPool } from 'amazon-cognito-identity-js';

const deactivateAccount = async (username, token, onDeactivated, onError) => {
    console.log('deactivate triggered');

    const poolData = {
        UserPoolId: 'us-west-1_nKKEndQj9',
        ClientId: '3enp9apsnc9iiq49q7ae6q008v'
    };

    const userPool = new CognitoUserPool(poolData);
    const cognitoUser = userPool.getCurrentUser();

    if (!cognitoUser) {
        onError("User is not authenticated");
        return;
    }

    try {
        const session = await getSession(cognitoUser);
        const jwtToken = session.getIdToken().getJwtToken(); // Extract JWT token from the session

        // Note: Removed the `deactivateUser` call here as requested

        await deleteUser(cognitoUser);

        const subscriptionId = ''; // Assuming subscriptionId is obtained elsewhere if deactivateUser is not called
        if (subscriptionId) {
            await cancelStripeSubscription(subscriptionId, jwtToken); // Use jwtToken for Stripe cancellation
        }
        onDeactivated({ message: 'Account deleted successfully', statusCode: 200 });
    } catch (error) {
        console.error("Error in deactivateAccount:", error);
        onError({
            message: error.message || "Unknown error occurred",
            statusCode: error.statusCode || 500
        });
    }
};

const getSession = (cognitoUser) => {
    return new Promise((resolve, reject) => {
        cognitoUser.getSession((err, session) => {
            if (err) reject(new Error("Error fetching session: " + err.message));
            else if (!session.isValid()) reject(new Error("Session is not valid"));
            else resolve(session);
        });
    });
};

// deactivateUser function removed as requested

const deleteUser = (cognitoUser) => {
    return new Promise((resolve, reject) => {
        cognitoUser.deleteUser((err) => {
            if (err) reject(new Error("Error deleting Cognito user: " + err.message));
            else resolve();
        });
    });
};

const cancelStripeSubscription = async (subscriptionId, jwtToken) => {
    const response = await fetch(`https://bb2b9p5bpk.execute-api.us-west-1.amazonaws.com/prod/cancelStripeSubscription/${subscriptionId}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwtToken}`,
        },
    });

    if (!response.ok) {
        throw new Error(`Failed to cancel Stripe subscription, status: ${response.status}`);
    }
};

export default deactivateAccount;
