import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { UseAuth } from './AuthContext';
import { Amplify } from 'aws-amplify';
import awsmobile from '../aws-exports';

// Add this line before using any Amplify functions
Amplify.configure(awsmobile);
// console.log('Amplify configured with:', awsmobile);

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

const VerifyCode = (props) => {
    const { username: paramUsername } = useParams();
    const username = props.username || paramUsername;
    const navigate = useNavigate();
    const query = useQuery();
    const returnUrl = query.get("returnUrl");
    const [verificationCode, setVerificationCode] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isResendingCode, setIsResendingCode] = useState(false);
    const location = useLocation();
    const { password } = location.state || {};  // Removed accountType
    // console.log("password on verify page", password)
    const { setToken } = UseAuth();

    useEffect(() => {
        if (!paramUsername) {
            console.error('Required information is missing. Redirecting to sign-up...');
            navigate('/sign-up');
        }
    }, [paramUsername, navigate]);

    const handleVerifyEmail = async (event) => {
        event.preventDefault();
        setIsLoading(true);
    
        // console.log('handleVerifyEmail triggered');
        // console.log('Username:', username);
        // console.log('Verification Code:', verificationCode);
    
        try {
            // Start the custom authentication flow
            const authResult = await Auth.signIn(username);
            // console.log('Custom auth flow initiated:', authResult);
    
            // console.log('sendCustomChallengeAnswer initiated.');
            // Send the custom challenge answer
            await Auth.sendCustomChallengeAnswer(authResult, verificationCode);
            // console.log('Verification code sent successfully.');
        } catch (error) {
            if (error.name === 'UserNotConfirmedException') {
                console.warn('Ignoring UserNotConfirmedException and redirecting to login.');
            } else {
                console.error('Error during email verification:', error);
                toast.error(error.message || 'Failed to verify email. Please try again.', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                setIsLoading(false);
                return;
            }
        }
    
        // Redirect to login page regardless of the UserNotConfirmedException
        toast.success('Verification successful! Please log in.', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
        setTimeout(() => navigate('/login'), 3200);
    
        setIsLoading(false);
        // console.log('handleVerifyEmail completed.');
    };
    
    







    const handleResendCode = async () => {
        setIsResendingCode(true);
        try {
            // Use resendSignUp to trigger the resend
            await Auth.resendSignUp(username);
            toast.success('Verification code resent. Please check your email.', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        } catch (error) {
            console.error('Error resending verification code:', error);
            toast.error(error.message || 'Failed to resend verification code. Please try again.', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        } finally {
            setIsResendingCode(false);
            console.log('handleResendCode completed.');
        }
    };
    


    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-100">
            <div className="bg-white p-6 rounded-lg shadow-md w-full max-w-md">
                <h2 className="text-2xl mb-4 text-center">Verify Email</h2>
                <p className="text-sm mb-4 text-center">
                    Your Injsur account has been created! A verification code has been sent to <span className="font-bold">{username}</span>. Please enter it below.
                </p>
                <p className="text-sm mb-4 text-center">
                    If you don't see the verification email, please check your spam folder and ensure that <span className="font-bold">support@injsur.ai</span> isn't blocked by your email provider.
                </p>
                <form onSubmit={handleVerifyEmail}>
                    <input
                        type="text"
                        name="verificationCode"
                        placeholder="Enter Verification Code"
                        value={verificationCode}
                        onChange={(e) => setVerificationCode(e.target.value)}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />

                    <button
                        type="submit"
                        disabled={isLoading}
                        className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full mt-4 ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                    >
                        {isLoading ? 'Processing...' : 'Verify Email'}
                    </button>
                </form>
                {/* <button
                    onClick={handleResendCode}
                    disabled={isResendingCode}
                    className={`mt-4 text-blue-500 hover:text-blue-700 ${isResendingCode ? 'opacity-50 cursor-not-allowed' : ''}`}
                >
                    {isResendingCode ? 'Resending...' : 'Resend Code'}
                </button> */}
            </div>
        </div>
    );
};

export default VerifyCode;
