import React, { useState } from 'react';
import UserHeader from './UserHeader';
import FrailtyTeam from './FrailtyTeam'; // Import the subcomponent
import teamsData from './teams.json'; // Assuming your teams.json is in the same directory

const Teams = () => {
    const [selectedTeam, setSelectedTeam] = useState(null); // State to track selected team
    const [searchTerm, setSearchTerm] = useState(''); // State for search term

    // Handle team click to show FrailtyTeams component
    const handleTeamClick = (team) => {
        setSelectedTeam(team); // Set selected team when clicked
    };

    // Back button handler to reset selected team
    const handleBack = () => {
        setSelectedTeam(null); // Set selected team back to null to return to the list of teams
    };

    // Sort teams by team name (city or team name) and filter for team IDs <= 32
    const sortedTeams = teamsData.response
        .filter(teamItem => teamItem.id <= 32) // Filter to include only teams with ID 1 through 32
        .sort((a, b) => a.name.localeCompare(b.name));

    // Filter teams based on search term
    const filteredTeams = sortedTeams.filter((teamItem) =>
        teamItem.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div>
            <UserHeader />

            {!selectedTeam ? ( // Show the list of teams if no team is selected
                <>
                    <h2 className="text-xl font-bold my-4 px-10">NFL Teams</h2>

                    {/* Search bar for filtering teams */}
                    <div className="px-10 mb-4">
                        <input
                            type="text"
                            placeholder="Search Teams..."
                            className="w-full md:w-1/3 p-2 border rounded-md"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)} // Update search term state
                        />
                    </div>

                    <div className="grid grid-cols-2 md:grid-cols-8 gap-4 px-10 pb-10">
                        {filteredTeams.map((teamItem, index) => (
                            <div
                                key={index}
                                className="cursor-pointer p-4 border rounded-lg hover:bg-gray-200"
                                onClick={() => handleTeamClick(teamItem)}
                            >
                                <img
                                    src={require(`./team_logos/${teamItem.id}.png`)} // Dynamically require the image
                                    alt={teamItem.name}
                                    className="w-16 h-16 mx-auto"
                                />
                                <h3 className="text-center mt-2">{teamItem.name}</h3>
                            </div>
                        ))}
                    </div>
                </>
            ) : ( // Show the FrailtyTeams component if a team is selected
                <FrailtyTeam
                    teamId={selectedTeam.id}
                    teamName={selectedTeam.name}
                    teamLogo={require(`./team_logos/${selectedTeam.id}.png`)} // Dynamically require the image
                    onBack={handleBack} // Pass the handleBack function
                />
            )}
        </div>
    );
};

export default Teams;
