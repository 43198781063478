import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import UserHeader from './UserHeader';
import { UseAuth } from './AuthContext';
import { useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Initialize Stripe.js with your publishable key
const getStripeKey = () => {
    const isLocalhost = window.location.href.includes('http://localhost:3000/');
    const isLeagueLink = window.location.href.includes('https://www.injsur.ai/');

    if (isLocalhost) {
        return 'pk_test_51PpgfUGrzdk3qn40VjV9BuM4On87glDerD3QFtirYH58uRk7yF59RvwpJQDP54VMLfJbldi8NbXl7j3f5ezCXkan00ZAawSzUZ';
    } else if (isLeagueLink) {
        return 'pk_live_51PpgfUGrzdk3qn40Ed5iDLfsdaxCtimHkhYUaub4kEhCvcgGRNychgbZwqbhZjvWorbp8hEZ8Bw10NMGFfxsHJuA00bz2sTt5w';
    }
    throw new Error('Invalid environment URL');
};

const stripePromise = loadStripe(getStripeKey());

const InnerForm = ({ subscriptionId, setSubscriptionDetails, prevStep }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [isProcessing, setIsProcessing] = useState(false);
    const { token } = UseAuth();

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!stripe || !elements) {
            return;
        }
    
        setIsProcessing(true);
    
        const cardElement = elements.getElement(CardElement);
    
        try {
            const { error, paymentMethod } = await stripe.createPaymentMethod({
                type: 'card',
                card: cardElement,
            });
    
            if (error) {
                console.log('[error]', error);
                toast.error(error.message || 'An unexpected error occurred');
                setIsProcessing(false);
                return;
            }
    
            const response = await fetch('https://s3z750e933.execute-api.us-west-1.amazonaws.com/prod/updatePaymentMethod', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
                body: JSON.stringify({
                    paymentMethodId: paymentMethod.id,
                    subscriptionId: subscriptionId,
                }),
            });
    
            const responseData = await response.json();
    
            if (response.ok) {
                setSubscriptionDetails(responseData);
                toast.success('Payment method updated successfully!');
            } else {
                throw new Error(responseData.message || 'Failed to update the payment method. Please try again later.');
            }
        } catch (error) {
            console.error('Error updating payment method:', error);
            toast.error(error.message || 'An error occurred while updating the payment method.');
        } finally {
            setIsProcessing(false);
        }
    };
    


    return (
        <form onSubmit={handleSubmit}>
            <div className="p-3 border-2 border-gray-300 rounded">
                <CardElement />
            </div>
            <div className="flex justify-between mt-8">
                <button type="button" onClick={prevStep} className="bg-gray-500 text-white py-2 px-4 rounded hover:bg-gray-600">
                    Back
                </button>
                <button type="submit" disabled={!stripe || isProcessing} className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600">
                    Update Payment Method
                </button>
            </div>
        </form>
    );
};

function UpdatePaymentMethod({ setSubscriptionDetails }) {
    const location = useLocation();
    const subscriptionId = location.state?.subscriptionId;
    const navigate = useNavigate();

    const prevStep = () => {
        navigate('/account');
    };

    return (
        <React.Fragment>
            <UserHeader />
            <div className=" min-h-screen bg-gray-100 flex flex-col">
                <div className="mx-auto mt-8 max-w-md p-6 bg-white shadow-md rounded-lg relative">
                    <p className='flex justify-center pt-6 mb-4 text-2xl text-black transition-all ease-in duration-125'>
                        Update Payment Method
                    </p>
                    <p className='flex justify-center pt-2  text-black transition-all ease-in duration-125'>
                        Please enter your new credit/debit card info below
                    </p>
                    <p className='flex items-center pt-2 p  mb-8 text-sm text-gray-600 transition-all ease-in duration-125'>
                        Note: If your subscription is past due, we will automatically attempt to charge your new payment method after it's updated.
                    </p>
                    <Elements stripe={stripePromise}>
                        <InnerForm
                            subscriptionId={subscriptionId}
                            setSubscriptionDetails={setSubscriptionDetails}
                            prevStep={prevStep}
                        />
                    </Elements>
                </div>
            </div>
        </React.Fragment>
    );
}

export default UpdatePaymentMethod;
