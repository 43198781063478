import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from './UserContext';
import UserHeader from './UserHeader';
import { CognitoUserPool, CognitoUser, CognitoUserAttribute } from 'amazon-cognito-identity-js';
import deactivateAccount from './DeactivateAccount';
import { UseAuth } from './AuthContext';
import UpdatePaymentMethod from './UpdatePaymentMethod';
import { toast } from 'react-toastify';

function UserAccount() {
    const { token } = UseAuth();
    const user = useUser();
    const navigate = useNavigate();

    const [email, setEmail] = useState(user?.email || '');
    const [phoneNumber, setPhoneNumber] = useState(user?.phoneNumber || '');
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showChangePassword, setShowChangePassword] = useState(false);
    const [showDeactivateModal, setShowDeactivateModal] = useState(false);
    const [isEditingEmail, setIsEditingEmail] = useState(false);
    const [isEditingPhone, setIsEditingPhone] = useState(false);
    const [deactivationError, setDeactivationError] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [subscriptionId, setSubscriptionId] = useState('');
    const [subscriptionDetails, setSubscriptionDetails] = useState(null);
    const [originalEmail, setOriginalEmail] = useState(user?.email || '');
    const [originalPhoneNumber, setOriginalPhoneNumber] = useState(user?.phoneNumber || '');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [isUpdatingField, setIsUpdatingField] = useState('');
    const [updateSuccessMessage, setUpdateSuccessMessage] = useState('');
    const [verificationCode, setVerificationCode] = useState(''); // For storing verification code
    const [showVerificationInput, setShowVerificationInput] = useState(false); // For showing the input after email update
    const [showOldPassword, setShowOldPassword] = useState(false); // State to show/hide old password
    const [showNewPassword, setShowNewPassword] = useState(false); // State to show/hide new password
    const [showConfirmPassword, setShowConfirmPassword] = useState(false); // State to show/hide confirm password

    useEffect(() => {
        // console.log("User object:", user);  // Log the user object to check its content

        setEmail(user?.email || '');
        setPhoneNumber(formatPhoneNumber(user?.phoneNumber || ''));  // Ensure phone number is formatted
        setOriginalEmail(user?.email || '');
        setOriginalPhoneNumber(formatPhoneNumber(user?.phoneNumber || ''));  // Ensure original phone number is formatted
        setFirstName(user?.firstName || '');  // Use firstName from user context
        setLastName(user?.lastName || '');    // Use lastName from user context
    }, [user]);






    useEffect(() => {
        // Define user pool details
        const userPool = new CognitoUserPool({
            UserPoolId: 'us-west-1_nKKEndQj9',
            ClientId: '3enp9apsnc9iiq49q7ae6q008v',
        });

        const cognitoUser = userPool.getCurrentUser();

        if (cognitoUser) {
            cognitoUser.getSession((err, session) => {
                if (err || !session.isValid()) {
                    console.error("Session is not valid:", err);
                    return;
                }

                // Retrieve user attributes
                cognitoUser.getUserAttributes((error, attributes) => {
                    if (error) {
                        console.error("Error fetching user attributes:", error);
                    } else {
                        const subscriptionAttr = attributes.find(attr => attr.Name === 'custom:subscription_id');
                        if (subscriptionAttr) {
                            // console.log("Fetched subscriptionId from Cognito:", subscriptionAttr.Value);
                            setSubscriptionId(subscriptionAttr.Value);
                        } else {
                            // console.log("custom:subscription_id attribute not found.");
                        }
                    }
                });
            });
        }
    }, []);


    const formatPhoneNumber = (value) => {
        if (value.startsWith('+1')) {
            value = value.substring(2);  // Remove +1 country code for display purposes
        }
        const cleaned = ('' + value).replace(/\D/g, '');  // Clean non-numeric characters
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return `(${match[1]})-${match[2]}-${match[3]}`;
        }
        return value;
    };


    const handlePhoneNumberChange = (e) => {
        const input = e.target.value.replace(/\D/g, '');  // Strip all non-numeric characters
        let formattedPhone = input;

        if (input.length > 0) {
            if (input.length <= 3) {
                formattedPhone = `(${input}`;
            } else if (input.length <= 6) {
                formattedPhone = `(${input.slice(0, 3)})-${input.slice(3)}`;
            } else {
                formattedPhone = `(${input.slice(0, 3)})-${input.slice(3, 6)}-${input.slice(6, 10)}`;
            }
        }

        setPhoneNumber(formattedPhone);
    };



    useEffect(() => {
        // console.log("useEffect triggered, subscriptionId:", subscriptionId, "token:", token);

        const fetchStripeSubscriptionDetails = async () => {
            // console.log("Attempting to fetch subscription details with subscriptionId:", subscriptionId);

            if (subscriptionId) {
                try {
                    // console.log("Fetching subscription details with token:", token);

                    const response = await fetch(`https://bb2b9p5bpk.execute-api.us-west-1.amazonaws.com/prod/getSubscriptionDetails/${subscriptionId}`, {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`,
                        },
                    });

                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }

                    const data = await response.json();
                    // console.log("Fetched subscription details:", data);

                    setSubscriptionDetails(data);
                } catch (error) {
                    // console.error("Error fetching subscription details:", error);
                }
            } else {
                // console.log("Subscription ID not set; skipping fetch.");
            }
        };

        fetchStripeSubscriptionDetails();
    }, [subscriptionId, token]);


    if (!user) {
        return (
            <div className="min-h-screen flex justify-center items-center bg-gray-200">
                Loading user information...
            </div>
        );
    }

    const handleChangePassword = () => {
        if (newPassword !== confirmPassword) {
            console.error("New passwords don't match!");
            return;
        }

        setIsUpdatingField('password');  // Show loader

        const userPool = new CognitoUserPool({
            UserPoolId: 'us-west-1_nKKEndQj9',
            ClientId: '3enp9apsnc9iiq49q7ae6q008v',
        });

        const cognitoUser = userPool.getCurrentUser();
        if (cognitoUser) {
            cognitoUser.getSession((err, session) => {
                if (err || !session.isValid()) {
                    console.error("Session is not valid", err);
                    setIsUpdatingField('');  // Stop loader
                    return;
                }
                cognitoUser.changePassword(oldPassword, newPassword, (changeErr) => {
                    setIsUpdatingField('');  // Stop loader

                    if (changeErr) {
                        console.error("Error changing password:", changeErr);
                    } else {
                        console.log("Password changed successfully");
                        setOldPassword('');
                        setNewPassword('');
                        setConfirmPassword('');
                        setUpdateSuccessMessage('Password updated successfully.');
                        setShowChangePassword(false);  // Hide password input fields

                        // Hide success message after 3 seconds
                        setTimeout(() => {
                            setUpdateSuccessMessage('');
                        }, 3000);
                    }
                });
            });
        }
    };


    const handleVerifyEmail = () => {
        const userPool = new CognitoUserPool({
            UserPoolId: 'us-west-1_nKKEndQj9',
            ClientId: '3enp9apsnc9iiq49q7ae6q008v',
        });
        const cognitoUser = userPool.getCurrentUser();

        if (cognitoUser) {
            cognitoUser.getSession((err, session) => {
                if (err) {
                    console.error("Session error:", err);
                    return;
                }

                // Verify the email with the code entered, passing proper callback structure
                cognitoUser.verifyAttribute('email', verificationCode, {
                    onSuccess: (result) => {
                        // console.log("Email verified successfully:", result);
                        setShowVerificationInput(false);  // Hide verification input after success
                        setUpdateSuccessMessage('Email updated and verified successfully.');

                        // Fetch updated user attributes after verification
                        cognitoUser.getUserAttributes((error, attributes) => {
                            if (error) {
                                console.error("Error fetching updated user attributes:", error);
                            } else {
                                const updatedEmailAttr = attributes.find(attr => attr.Name === 'email');
                                if (updatedEmailAttr) {
                                    setEmail(updatedEmailAttr.Value);  // Update email in state
                                    setOriginalEmail(updatedEmailAttr.Value);  // Reset original email in state
                                }
                            }
                        });

                        // Clear the success message after 3 seconds
                        setTimeout(() => {
                            setUpdateSuccessMessage('');
                        }, 3000);
                    },
                    onFailure: (verifyErr) => {
                        console.error("Verification failed:", verifyErr);
                        // Handle the failure case (e.g., show error message to the user)
                    }
                });
            });
        }
    };



    const handleUpdate = (field) => {
        setIsUpdatingField(field);  // Set the specific field being updated
        const userPool = new CognitoUserPool({
            UserPoolId: 'us-west-1_nKKEndQj9',
            ClientId: '3enp9apsnc9iiq49q7ae6q008v',
        });
        const phoneNumberForCognito = `+1${phoneNumber.replace(/\D/g, '')}`;  // Clean the phone number to +1 format

        const cognitoUser = userPool.getCurrentUser();
        if (cognitoUser) {
            cognitoUser.getSession((err, session) => {
                if (err) {
                    setIsUpdatingField('');  // Reset the loading state on error
                    return console.error(err);
                }

                const attributes = [];
                if (field === 'email' && email !== user.email) {
                    attributes.push(new CognitoUserAttribute({ Name: 'email', Value: email }));
                }
                if (field === 'phone_number' && phoneNumber !== user.phoneNumber) {
                    attributes.push(new CognitoUserAttribute({ Name: 'phone_number', Value: phoneNumberForCognito }));
                }

                if (attributes.length > 0) {
                    cognitoUser.updateAttributes(attributes, (updateErr) => {
                        setIsUpdatingField('');  // Reset the loading state
                        if (updateErr) {
                            console.error(updateErr);
                        } else {
                            // Show verification input for email after update
                            if (field === 'email') {
                                setIsEditingEmail(false);  // Hide email editing and button
                                setShowVerificationInput(true);  // Show verification input for the code
                            }
                            if (field === 'phone_number') {
                                setIsEditingPhone(false);  // Hide phone number editing and button
                                setUpdateSuccessMessage(`Updated ${field} successfully.`);
                                setTimeout(() => {
                                    setUpdateSuccessMessage('');
                                }, 3000);
                            }
                        }
                    });
                } else {
                    setIsUpdatingField('');  // Reset if no attributes need updating
                }
            });
        }
    };


    const handleDeactivateAccount = () => {
        console.log("Initiating account deactivation...");
        console.log("Username:", user.username);
        console.log("Token:", token);
    
        deactivateAccount(user.username, token, (result) => {
            console.log("Deactivation result:", result);
    
            if (result?.statusCode === 200) {
                console.log("Account deactivated successfully. Redirecting to login...");
                setShowDeactivateModal(false);
                navigate('/login');
    
                // Display success toast notification
                toast.success('Account deleted successfully.', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            } else {
                console.log("Deactivation failed with unexpected status code:", result?.statusCode);
                setDeactivationError("Unexpected error occurred. Please try again.");
            }
        }, (error) => {
            console.log("Deactivation error:", error);
            const errorMessage = error.message || "An error occurred while trying to deactivate your account.";
            console.log("Setting deactivation error message:", errorMessage);
            setDeactivationError(errorMessage);
    
            // Display error toast notification
            toast.error(errorMessage, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        });
    };
    
    
    

    const confirmDeactivation = () => {
        handleDeactivateAccount();
    };

    const handleInputChange = (event) => {
        const value = event.target.value;
        setInputValue(value);
        setIsButtonDisabled(value.toLowerCase() !== 'delete');
    };

    const formatAmount = (amount) => `$${(amount / 100).toFixed(2)}`;
    const formatDate = (dateString) => new Date(dateString).toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' });

    const isUpdateVisible = isEditingEmail || isEditingPhone;

    const goToUpdatePaymentMethod = () => {
        navigate('/update-payment-method', { state: { subscriptionId } });
    };

    const statusColors = {
        active: "text-green-500",
        past_due: "text-red-500",
        canceled: "text-gray-500",
        unpaid: "text-yellow-500",
    };

    const getStatusColor = (status) => statusColors[status] || "text-black";
    const capitalizeFirstLetters = (str) => str?.replace(/_/g, ' ').replace(/\b(\w)/g, s => s.toUpperCase()) || '';

    const handleCancelEmailEdit = () => {
        setEmail(originalEmail);
        setIsEditingEmail(false);
    };

    const handleCancelPhoneEdit = () => {
        setPhoneNumber(formatPhoneNumber(originalPhoneNumber));
        setIsEditingPhone(false);
    };


    return (
        <div className="min-h-screen bg-gray-100 flex flex-col">
            <UserHeader />
            <div className="flex-1 flex justify-center items-center">
                <div className="w-3/4 md:w-1/2 lg:w-1/3 bg-white p-8 rounded-xl shadow-lg">
                    <h3 className="text-2xl mb-4">User Account Information</h3>
                    <p className="mb-2"><strong className="font-semibold">First Name:</strong> {user?.firstName || ''}</p>
                    <p className="mb-2"><strong className="font-semibold">Last Name:</strong> {user?.lastName || ''}</p>


                    <div className="mb-4 flex flex-col">
                        <div className="flex items-center">
                            <strong className="font-semibold">Phone Number:</strong>
                            {isEditingPhone ? (
                                <input
                                    className="ml-2 p-2 border rounded w-1/2"
                                    value={phoneNumber}
                                    onChange={handlePhoneNumberChange}
                                />
                            ) : (
                                <span className="ml-2">{formatPhoneNumber(phoneNumber)}</span>
                            )}
                            <button
                                onClick={() => {
                                    if (isEditingPhone) {
                                        handleCancelPhoneEdit();
                                    } else {
                                        setIsEditingPhone(true);
                                    }
                                }}
                                className="ml-2 text-blue-500"
                            >
                                {isEditingPhone ? "Cancel" : "Edit"}
                            </button>
                        </div>
                        {isEditingPhone && (
                            <>
                                {!updateSuccessMessage && (
                                    <button
                                        onClick={() => handleUpdate('phone_number')}
                                        className={`mt-2 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 ${isUpdatingField === 'phone_number' ? 'cursor-not-allowed' : ''}`}
                                        disabled={isUpdatingField === 'phone_number'}
                                    >
                                        {isUpdatingField === 'phone_number' ? (
                                            <span className="loader">Loading...</span>  // Spinner while updating
                                        ) : (
                                            'Update Phone Number'
                                        )}
                                    </button>
                                )}
                            </>
                        )}

                        {updateSuccessMessage === 'Updated phone_number successfully.' && (
                            <p className="text-green-500">Phone Number updated successfully</p>  // Success message visible after the button disappears
                        )}

                    </div>
                    <div className="mb-4 flex flex-col">
                        <div className="flex items-center">
                            <strong className="font-semibold">Email:</strong>
                            {isEditingEmail ? (
                                <input
                                    className="ml-2 p-2 border rounded w-1/2"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            ) : (
                                <span className="ml-2">{email}</span>
                            )}
                            <button
                                onClick={() => {
                                    if (isEditingEmail) {
                                        handleCancelEmailEdit();
                                    } else {
                                        setIsEditingEmail(true);
                                    }
                                }}
                                className="ml-2 text-blue-500"
                            >
                                {isEditingEmail ? "Cancel" : "Edit"}
                            </button>
                        </div>

                        {isEditingEmail && (
                            <>
                                {!updateSuccessMessage && (
                                    <button
                                        onClick={() => handleUpdate('email')}
                                        className={`mt-2 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 ${isUpdatingField === 'email' ? 'cursor-not-allowed' : ''}`}
                                        disabled={isUpdatingField === 'email'}
                                    >
                                        {isUpdatingField === 'email' ? (
                                            <span className="loader">Loading...</span>
                                        ) : (
                                            'Update Email'
                                        )}
                                    </button>
                                )}
                            </>
                        )}


                        {updateSuccessMessage === 'Updated email successfully.' && (
                            <p className="text-green-500">Email updated successfully</p>
                        )}

                        {/* Insert the verification input block here */}
                        {showVerificationInput && (
                            <div className="mt-4">
                                <input
                                    type="text"
                                    placeholder="Enter verification code"
                                    value={verificationCode}
                                    onChange={(e) => setVerificationCode(e.target.value)}
                                    className="p-2 border rounded w-1/2"
                                />
                                <button
                                    onClick={handleVerifyEmail}
                                    className="ml-2 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
                                >
                                    Verify Email
                                </button>
                            </div>
                        )}
                        {updateSuccessMessage === 'Email updated and verified successfully.' && (
                            <p className="text-green-500">Email updated and verified successfully</p>
                        )}

                    </div>

                    <p className="mb-2">
                        <strong className="font-semibold">Subscription: </strong>
                        {subscriptionDetails ? `${formatAmount(subscriptionDetails.amount)} per month` : 'Expires Feb. 10th 2025'}

                    </p>
                    <p>Status: <span className={getStatusColor(subscriptionDetails?.status)}>{capitalizeFirstLetters(subscriptionDetails?.status)}</span></p>
                    {subscriptionDetails && <p className="text-gray-500">Next Payment On: {formatDate(subscriptionDetails.nextDueDate)}</p>}
                    <button className="text-blue-500" onClick={goToUpdatePaymentMethod}>Update Payment Method</button>
                    <div className="mb-4 mt-6">
                        <strong
                            className="font-semibold cursor-pointer text-blue-500 hover:text-blue-600"
                            onClick={() => setShowChangePassword(!showChangePassword)}>
                            {showChangePassword ? "Cancel" : "Change Password"}
                        </strong>
                        {showChangePassword && (
                            <>
                                <div className="relative mb-2">
                                    <input
                                        className="p-2 border rounded w-full pr-10" // Add padding-right for the icon
                                        type={showOldPassword ? 'text' : 'password'}  // Toggle between 'text' and 'password'
                                        placeholder="Old Password"
                                        value={oldPassword}
                                        onChange={(e) => setOldPassword(e.target.value)}
                                    />
                                    <span
                                        onClick={() => setShowOldPassword(!showOldPassword)}
                                        className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                                    >
                                        {showOldPassword ? '👁️' : '👁️‍🗨️'} {/* Change icon based on visibility */}
                                    </span>
                                </div>

                                <div className="relative mb-2">
                                    <input
                                        className="p-2 border rounded w-full pr-10" // Add padding-right for the icon
                                        type={showNewPassword ? 'text' : 'password'}  // Toggle between 'text' and 'password'
                                        placeholder="New Password"
                                        value={newPassword}
                                        onChange={(e) => setNewPassword(e.target.value)}
                                    />
                                    <span
                                        onClick={() => setShowNewPassword(!showNewPassword)}
                                        className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                                    >
                                        {showNewPassword ? '👁️' : '👁️‍🗨️'} {/* Change icon based on visibility */}
                                    </span>
                                </div>

                                <div className="relative mb-2">
                                    <input
                                        className="p-2 border rounded w-full pr-10" // Add padding-right for the icon
                                        type={showConfirmPassword ? 'text' : 'password'}  // Toggle between 'text' and 'password'
                                        placeholder="Confirm New Password"
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                    />
                                    <span
                                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                        className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                                    >
                                        {showConfirmPassword ? '👁️' : '👁️‍🗨️'} {/* Change icon based on visibility */}
                                    </span>
                                </div>

                            </>
                        )}

                        {/* Success Message should always be visible outside of the password input conditional */}
                        {updateSuccessMessage === 'Password updated successfully.' && (
                            <p className="text-green-500 mt-2">Password updated successfully</p>
                        )}

                    </div>
                    {/* <button
                        onClick={handleUpdate}
                        className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
                        style={{ display: isUpdateVisible ? 'block' : 'none' }}>
                        Update Information
                    </button> */}
                    <div className="text-center mt-4">
                        <span
                            className="text-red-500 cursor-pointer hover:underline"
                            onClick={() => setShowDeactivateModal(true)}>
                            Delete Account
                        </span>
                    </div>
                </div>
            </div>
            {showDeactivateModal && (
                <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-50">
                    <div className="bg-black opacity-50 absolute top-0 left-0 w-full h-full"></div>
                    <div className="bg-white p-6 rounded-lg shadow-lg relative z-10" style={{ width: '500px' }}>
                        <h2 className="font-bold text-xl mb-4">Confirm Deletion</h2>
                        <p>
                            Are you sure you want to delete this account? This will also cancel your current subscription.
                            <strong> This action is permanent and cannot be undone.</strong>
                        </p>
                        {deactivationError && (
                            <div className="alert alert-danger mt-4 text-red-600">
                                {deactivationError}
                            </div>
                        )}
                        <input
                            type="text"
                            placeholder="Type 'delete' to confirm"
                            value={inputValue}
                            onChange={handleInputChange}
                            className="w-full mt-4 p-2 border rounded"
                        />
                        <div className="mt-6 flex justify-between">
                            <button onClick={() => setShowDeactivateModal(false)} className="px-4 py-2 bg-gray-600 text-white rounded hover:bg-gray-700">Cancel</button>
                            <button
                                onClick={confirmDeactivation}
                                className={`px-4 py-2 text-white rounded ${isButtonDisabled ? 'bg-gray-400 hover:bg-gray-400 cursor-not-allowed' : 'bg-red-600 hover:bg-red-700'}`}
                                disabled={isButtonDisabled}>
                                Delete
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default UserAccount;
