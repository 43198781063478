import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram, faXTwitter, faTiktok } from '@fortawesome/free-brands-svg-icons';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import logo from '../images/injsurlogo2.png'; // Adjust the path based on your project structure

const Header2 = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation();

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const getActiveTab = () => {
    switch (location.pathname) {
      case '/about':
        return 'ABOUT US';
      case '/articles':
        return 'ARTICLES';
      case '/faq':
        return 'FAQ';
      case '/learn-more':
        return 'INSIGHTS';
      default:
        return 'HOME';
    }
  };

  return (
    <div
      className={`sticky top-0 w-full z-50  px-4 md:px-12 flex items-center justify-between transition-all duration-300 bg-zinc-900`}
    >
      <div className="flex items-center space-x-4 md:space-x-6">
        <Link to="/">
          <div className="flex items-center">
            <img src={logo} alt="Logo" className="h-20 md:h-20 " />
            <span className="text-[#fbf4e4] text-sm pt-10 pr-6 font-bold">™</span>
          </div>
        </Link>

        {/* Left Side: Navigation Links */}
        <div className="hidden xl:flex space-x-6" style={{ fontFamily: 'Montserrat, sans-serif' }}>
          <Link
            to="/"
            className={`text-lg font-semibold border-b-2 transition duration-200 ${getActiveTab() === 'HOME'
              ? 'text-[#d8bf82] border-[#d8bf82]'
              : 'text-white border-transparent hover:border-gray-300 hover:text-gray-300'
              }`}
          >
            HOME
          </Link>
          <Link
            to="/about"
            className={`text-lg font-semibold border-b-2 transition duration-200 ${getActiveTab() === 'ABOUT US'
              ? 'text-[#d8bf82] border-[#d8bf82]'
              : 'text-white border-transparent hover:border-gray-300 hover:text-gray-300'
              }`}
          >
            ABOUT
          </Link>


          <Link
            to="/learn-more"
            className={`text-lg font-semibold border-b-2 transition duration-200 ${getActiveTab() === 'INSIGHTS'
              ? 'text-[#d8bf82] border-[#d8bf82]'
              : 'text-white border-transparent hover:border-gray-300 hover:text-gray-300'
              }`}
          >
            INSIGHTS
          </Link>
          <Link
            to="/faq"
            className={`text-lg font-semibold border-b-2 transition duration-200 ${getActiveTab() === 'FAQ'
              ? 'text-[#d8bf82] border-[#d8bf82]'
              : 'text-white border-transparent hover:border-gray-300 hover:text-gray-300'
              }`}
          >
            FAQ
          </Link>
        </div>
      </div>

      <div className="flex items-center space-x-4 md:space-x-12">
      <div className="hidden lg:flex space-x-4 md:space-x-8 items-center">
      <a href="https://www.tiktok.com/@injsur.ai?_t=8qT8iCnoS79&_r=1" className="text-white text-2xl hover:text-gray-300 transition duration-200">
            <FontAwesomeIcon icon={faTiktok} />
          </a>
          <a href="https://www.facebook.com/profile.php?id=61567261103438&mibextid=LQQJ4d" className="text-white text-2xl hover:text-gray-300 transition duration-200">
            <FontAwesomeIcon icon={faFacebookF} />
          </a>
          <a href="https://www.instagram.com/injsur.ai?igsh=MzRlODBiNWFlZA==" className="text-white text-2xl hover:text-gray-300 transition duration-200">
            <FontAwesomeIcon icon={faInstagram} />
          </a>
          <a href="https://x.com/injsur_ai?s=21" className="text-white text-2xl hover:text-gray-300 transition duration-200">
            <FontAwesomeIcon icon={faXTwitter} />
          </a>
          <Link to="/login">
            <button
              className="bg-transparent font-bold text-white text-lg px-4 md:px-8 py-2 rounded-full hover:bg-white hover:text-black transition duration-200"
              style={{
                fontFamily: 'Montserrat, sans-serif',
                backgroundColor: '#d8bf82',
                color: 'black',
                borderColor: 'white',
              }}
              onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#a78c5d')}
              onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = '#d8bf82')}
            >
              JOIN OR LOG IN
            </button>
          </Link>
        </div>
        <button className="xl:hidden text-white text-3xl pr-8" onClick={toggleDrawer}>
          <FontAwesomeIcon icon={isDrawerOpen ? faTimes : faBars} />
        </button>
      </div>

      {/* Drawer: Visible on Mobile */}
      {isDrawerOpen && (
        <div className="fixed top-0 left-0 h-screen w-full bg-zinc-900 bg-opacity-100 flex flex-col items-center z-50 transition-opacity duration-300 ease-in-out animate-fadeIn">
          <button className="absolute top-5 right-5 text-white text-3xl" onClick={toggleDrawer}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
          <img src={logo} alt="Logo" className="h-24 mb-8 mt-10" />
          <Link
            to="/"
            onClick={toggleDrawer}
            className={`mb-2 text-white text-2xl font-semibold border-b-2 ${getActiveTab() === 'HOME' ? 'border-white' : 'border-transparent hover:border-white'
              } transition duration-200`}
          >
            Home
          </Link>
          <Link
            to="/about"
            onClick={toggleDrawer}
            className={`mb-2 text-white text-2xl font-semibold border-b-2 ${getActiveTab() === 'ABOUT US' ? 'border-white' : 'border-transparent hover:border-white'
              } transition duration-200`}
          >
            About Us
          </Link>

          <Link
            to="/insights"
            onClick={toggleDrawer}
            className={`mb-2 text-white text-2xl font-semibold border-b-2 ${getActiveTab() === 'INSIGHTS' ? 'border-white' : 'border-transparent hover:border-white'
              } transition duration-200`}
          >
            Insights
          </Link>
          <Link
            to="/faq"
            onClick={toggleDrawer}
            className={`mb-2 text-white text-2xl font-semibold border-b-2 ${getActiveTab() === 'FAQ' ? 'border-white' : 'border-transparent hover:border-white'
              } transition duration-200`}
          >
            FAQ
          </Link>

          <div className="flex space-x-4 items-center pt-10">
            <a href="https://www.tiktok.com/@injsur.ai?_t=8qT8iCnoS79&_r=1" className="text-white text-2xl hover:text-gray-300 transition duration-200">
              <FontAwesomeIcon icon={faTiktok} />
            </a>
            <a href="https://www.facebook.com/profile.php?id=61567261103438&mibextid=LQQJ4d" className="text-white text-xl hover:text-gray-300 transition duration-200">
              <FontAwesomeIcon icon={faFacebookF} />
            </a>
            <a href="https://www.instagram.com/injsur.ai?igsh=MzRlODBiNWFlZA==" className="text-white text-xl hover:text-gray-300 transition duration-200">
              <FontAwesomeIcon icon={faInstagram} />
            </a>
            <a href="https://x.com/injsur_ai?s=21" className="text-white text-xl hover:text-gray-300 transition duration-200">
              <FontAwesomeIcon icon={faXTwitter} />
            </a>

          </div>
          <Link to="/login">
            <button className="mt-10 bg-transparent font-bold border-2 border-white text-white text-lg px-8 py-4 rounded-full hover:bg-white hover:text-black transition duration-200">
              JOIN OR LOG IN
            </button>
          </Link>
        </div>
      )}
    </div>
  );
};

export default Header2;
