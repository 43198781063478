import React, { useEffect, useRef } from 'react';
import { Auth } from 'aws-amplify';
import { UseAuth } from './AuthContext';

function TokenTimeout() {
    const { token, isTokenExpired, refreshAccessToken } = UseAuth();
    const tokenRef = useRef(token); // Use a ref to hold the latest token

    useEffect(() => {
        tokenRef.current = token; // Update the ref whenever the token changes
    }, [token]);

    useEffect(() => {
        const proactiveRefresh = () => {
            const token = tokenRef.current; // Always use the latest token
            if (!token) {
                // console.log("No token available to check.");
                return;
            }

            const payload = JSON.parse(atob(token.split('.')[1]));
            const expirationTime = payload.exp * 1000; // Convert to milliseconds
            // console.log(`expirationTime`, expirationTime);

            const currentTime = Date.now();
            const remainingTime = expirationTime - currentTime;
            // console.log(`Proactive refresh check initiated. Remaining time before token expires:`, remainingTime);
            
            const refreshThreshold = 5 * 60 * 1000; // Refresh 5 minutes before the token expires
            if (remainingTime < refreshThreshold) {
                if (!isTokenExpired(token)) {
                    // console.log("Remaining time is below the threshold. Attempting to refresh the token.");
                    refreshAccessToken();
                } else {
                    // console.log("Token is marked as expired. Proceeding to logout.");
                    handleLogout();
                }
            } else {
                // console.log("No need to refresh token yet. Remaining time is above the threshold.");
            }
        };

        const handleLogout = () => {
            // console.log("Logging out user due to token issues.");
            Auth.signOut();
            clearLocalStorage();
            window.location.href = "/login";
        };

        const clearLocalStorage = () => {
            // console.log("Clearing local storage.");
            localStorage.removeItem('savedEmail');
            localStorage.removeItem('savedPassword');
            localStorage.removeItem('userToken');
        };

        // console.log("Setting up token refresh interval.");
        const refreshInterval = setInterval(proactiveRefresh, 60 * 1000); // Check every 10 seconds

        return () => {
            // console.log("Clearing token refresh interval.");
            clearInterval(refreshInterval);
        };
    }, []); // Empty dependency array to ensure setup is run once per mount

    return null;
}

export default TokenTimeout;
